import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal, DateFilters } from 'shared';
import { IconRemove, IconEdit, IconSearch } from 'shared/Icons';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { defaultDateFormat } from 'shared/constants';
import '../../styles.scss';

import api from 'helpers/api';
import { styledAssignedEquipmentStatusOptions } from 'industry/helpers';

import { deleteAssignedEquipment, getAssignedEquipment, getPaginatedAssignedEquipment, getWorkersList } from '../../actions';

import AssignedEquipmentModal from './AssignedEquipmentModal';

const AssignedEquipment = ({ t, companyId, locationId, currentUser, userHaveFullAccess }) => {
  const [assignedEquipment, setAssignedEquipment] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [assignedEquipmentFilters, setAssignedEquipmentFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'date_of_take',
  });
  const [selectedFilters, setSelectedFilters] = useState({
    filters: '',
    start: null,
    end: null,
    status: null,
  });
  const [query, setQuery] = useState(null);
  const debounceTimeoutRef = useRef(null);

  const [workers, setWorkers] = useState([]);
  const [takenByWorkers, setTakenByWorkers] = useState([]);
  const [acceptedByWorkers, setAcceptedByWorkers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchAssignedEquipment = () => {
    setAssignedEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = assignedEquipmentFilters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `&limit=30&order_by=${asc}${assignedEquipmentFilters?.selectedSort}`;

    if (selectedFilters?.status) apiFilters += `&status=${selectedFilters?.status?.value}`;

    if (selectedFilters?.start && selectedFilters?.end && selectedFilters?.filters) {
      apiFilters += `&${selectedFilters.filters}_after=${moment(selectedFilters.start).format('YYYY-MM-DD')}&${selectedFilters.filters}_before=${moment(selectedFilters.end).format('YYYY-MM-DD')}`;
    }

    if (query) apiFilters += `&first_or_last_name=${query}`;

    getAssignedEquipment(companyId, apiFilters)
      .then((res) => {
        setAssignedEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAssignedEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedAssignedEquipment = (url) => {
    setAssignedEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedAssignedEquipment(url)
      .then((res) => {
        setAssignedEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAssignedEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchWorkers = (key, value) => {
    setIsLoading(true);

    let filters = '';
    if (value) filters += `&first_or_last_name=${value}`;

    getWorkersList(locationId, companyId, filters)
      .then((res) => {
        const result = get(res, 'data.results', []);
        if (key === 'worker') {
          setIsLoading(false);
          setWorkers(result);
        } else if (key === 'take_clerk') {
          setIsLoading(false);
          setTakenByWorkers(result);
        } else if (key === 'return_clerk') {
          setIsLoading(false);
          setAcceptedByWorkers(result);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAssignedEquipment();
  }, [companyId]);

  useEffect(() => {
    fetchAssignedEquipment();
  }, [assignedEquipmentFilters, selectedFilters]);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchAssignedEquipment();
      }, 300);
    }
  }, [query]);

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteAssignedEquipment(companyId, deleteData?.original?.id);
    setShowConfirmationDialog(false);
    fetchAssignedEquipment();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setAssignedEquipmentFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAssignNewEquipment = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setWorkers([]);
    setTakenByWorkers([]);
    setAcceptedByWorkers([]);
    setIsLoading(false);
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    setWorkers([]);
    setTakenByWorkers([]);
    setAcceptedByWorkers([]);
    setIsLoading(false);
    handleCloseModal();
    fetchAssignedEquipment();
  };

  const handleClearFilters = () => {
    setSelectedFilters({ filters: '', start: null, end: null, status: null });
    setQuery(null);
  };

  const handleExportAssignedEquipment = () => {
    const asc = assignedEquipmentFilters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `&order_by=${asc}${assignedEquipmentFilters?.selectedSort}`;

    if (selectedFilters?.status) apiFilters += `&status=${selectedFilters?.status?.value}`;

    if (selectedFilters?.start && selectedFilters?.end && selectedFilters?.filters) {
      apiFilters += `&${selectedFilters.filters}_after=${moment(selectedFilters.start).format('YYYY-MM-DD')}&${selectedFilters.filters}_before=${moment(selectedFilters.end).format('YYYY-MM-DD')}`;
    }

    if (query) apiFilters += `&first_or_last_name=${query}`;

    api.get(`/api/v1/workforce/equipments/?company=${companyId}${apiFilters}&format=xlsx&limit=${assignedEquipment?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Assigned_equipment.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const equipmentStatus = [
    { value: 'taken', name: t('page_content.human_resources.assigned_equipment.status_taken') },
    { value: 'returned', name: t('page_content.human_resources.assigned_equipment.status_returned') },
    { value: 'lost', name: t('page_content.human_resources.assigned_equipment.status_lost') },
    { value: 'damaged', name: t('page_content.human_resources.assigned_equipment.status_damaged') },
  ];

  const equipmentStatusFilterOptions = [
    { id: 'date_of_take', label: t('page_content.human_resources.assigned_equipment.filter_options_date_of_take'), showTime: false },
    { id: 'date_of_return', label: t('page_content.human_resources.assigned_equipment.filter_options_date_of_return'), showTime: false },
  ];

  return (
    <div className="assigned_equipment">
      <div className="assigned_equipment__table">
        <div className="assigned_equipment__filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.human_resources.assigned_equipment.filter_placeholder_search')} value={query || ''} />
              {query && <button
                onClick={() => setQuery('')}
              >&times;</button>}
            </div>
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div>
            <DateFilters
              selectedFilterProp={(filters, start, end) => setSelectedFilters((prevState) => ({ ...prevState, filters, start, end }))}
              filterOptions={equipmentStatusFilterOptions}
              defaultDate={selectedFilters}
            />
          </div>
          <div style={{ width: '200px' }}>
            <Select
              options={equipmentStatus}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isClearable
              menuPosition="fixed"
              placeholder={t('page_content.human_resources.assigned_equipment.filter_placeholder_status')}
              onChange={(value) => setSelectedFilters((prevState) => ({ ...prevState, status: value }))}
              value={(equipmentStatus?.find((a) => a.value === selectedFilters?.status?.value)) || ''}
              styles={selectModalStyles}
            />
          </div>
          <Button onClick={handleClearFilters}>{t('page_content.human_resources.assigned_equipment.button_clear_all')}</Button>
          <div className="assigned_equipment__filters__actions">
            <Button disabled={!userHaveFullAccess} type="add" onClick={handleAssignNewEquipment}>{t('page_content.human_resources.assigned_equipment.button_add_new')}</Button>
            <Button type="export" onClick={handleExportAssignedEquipment}>{t('page_content.human_resources.assigned_equipment.button_export')}</Button>
          </div>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_name')}</span>,
              accessor: 'name',
              Cell: (row) => (row?.value ? row.value : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_quantity')}</span>,
              accessor: 'quantity',
              width: 100,
              Cell: (row) => (row?.value ? row.value : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_taken')}</span>,
              accessor: 'date_of_take',
              width: 100,
              Cell: (row) => (row?.value ? moment(row.value, 'YYYY-MM-DD').format(defaultDateFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_returned')}</span>,
              accessor: 'date_of_return',
              width: 100,
              Cell: (row) => (row?.value ? moment(row.value, 'YYYY-MM-DD').format(defaultDateFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_note')}</span>,
              accessor: 'note',
              width: 300,
              Cell: (row) => (row?.value ? row.value : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_status')}</span>,
              accessor: 'status',
              Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={row?.value && equipmentStatus?.find((val) => val.value === row?.value)?.name ? styledAssignedEquipmentStatusOptions(row?.value) : {}}>
                {row?.value ? equipmentStatus?.find((val) => val.value === row?.value)?.name : '-'}</span>
            </div>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_worker')}</span>,
              accessor: 'worker',
              Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_take_clerk')}</span>,
              accessor: 'take_clerk',
              Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_return_clerk')}</span>,
              accessor: 'return_clerk',
              Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  disabled={!userHaveFullAccess}
                  onClick={() => handleTableRowClick(get(row, 'original'))}
                >
                  <IconEdit width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  disabled={!userHaveFullAccess}
                  type="delete"
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
          ]}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultPageSize={100}
          showPagination={false}
          data={assignedEquipment?.data || []}
          loading={assignedEquipment?.isLoading}
          defaultSorted={[{ id: 'date_of_take', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              next={assignedEquipment?.next}
              count={assignedEquipment?.count}
              previous={assignedEquipment?.previous}
              fetchFunction={fetchPaginatedAssignedEquipment}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <AssignedEquipmentModal
        workers={workers}
        currentUser={currentUser}
        isOpen={modalData?.isOpen}
        fetchWorkers={fetchWorkers}
        isLoadingWorkers={isLoading}
        takenByWorkers={takenByWorkers}
        equipmentStatus={equipmentStatus}
        handleCloseModal={handleCloseModal}
        acceptedByWorkers={acceptedByWorkers}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'original.name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

AssignedEquipment.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default (withTranslation()(AssignedEquipment));
