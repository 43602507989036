import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { IconX } from 'shared/Icons';
import { getLocale } from 'shared/DatePicker/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { modalSizes, defaultDateFormat } from 'shared/constants';
import { styledAssignedEquipmentStatusOptions } from 'industry/helpers';
import { Modal, Button, Table, ContentLoader, TableButtons } from 'shared';
import './styles.scss';

import { getAssignedEquipment, getPaginatedAssignedEquipment } from '../../actions';
import {
  addEmployment,
  addTerminationReasons,
  deleteDocument,
  editDocument,
  editEmployment,
  finishSurvey,
  getDocumentTypes,
  getForms,
  getQuestionOptions,
  getQuestions,
  getSurveyAnswers,
  getWorkerDocuments,
  getWorkerSurveys,
  uploadDocument,
} from './actions';

import FormLayoffModal from './FormLayoffModal';

const AddLayoffModal = ({
  showModal,
  t,
  companyId,
  handleClose,
  handleAddLayoff,
  workers,
  currentUser,
  reasons,
  fetchReasons,
  initialData,
  layoffCategoryCode,
}) => {
  const [showAddReasons, setShowAddReason] = useState(false);
  const [newReasonInput, setNewReasonInput] = useState('');
  const [layoffData, setLayoffData] = useState(initialData || {});
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [initialDocuments, setInitialDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectReasons, setSelectReasons] = useState([]);
  const [showFormModal, setShowFormModal] = useState(false);
  const [layoffForms, setLayoffForms] = useState([]);
  const [surveyData, setSurveyData] = useState({});
  const [workerSurveyId, setWorkerSurveyId] = useState(null);
  const [formQuestions, setFormQuestions] = useState([]);
  const [workerSurveyData, setWorkerSurveyData] = useState(null);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [documentsValid, setDocumentsValid] = useState(true);
  const [documentsUploaded, setDocumentsUploaded] = useState(false);
  const dateFormat = 'YYYY-MM-DD';
  const [assignedEquipment, setAssignedEquipment] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });

  useEffect(() => {
    if (workerSurveyData) {
      setIsLoadingForm(false);
    }
  }, [workerSurveyData]);

  useEffect(() => {
    if (selectedDocument?.length) {
      setIsLoadingDocuments(false);
    }
  }, [selectedDocument]);

  const handleChange = (value, key) => {
    setLayoffData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchDocumentTypes = async () => {
    const res = await getDocumentTypes(companyId);
    const docTypes = get(res, 'data.results', []);
    setDocumentTypes(docTypes);
  };

  const fetchWorkerDocuments = async () => {
    const resp = await getWorkerDocuments(companyId, layoffData?.worker?.id);
    const workerDocuments = get(resp, 'data.results', []);
    const layoffWorkerDocuments = workerDocuments.filter((d) => d.employment === layoffData.id);
    if (!layoffWorkerDocuments?.length) {
      setIsLoadingDocuments(false);
      return;
    }
    setInitialDocuments(layoffWorkerDocuments);
    setSelectedDocument(layoffWorkerDocuments);
  };

  const fetchForms = async () => {
    const res = await getForms(companyId);
    const formsData = get(res, 'data.results', []);
    const layoffFormFound = formsData.filter((f) => (f?.category?.code === layoffCategoryCode));
    if (layoffFormFound) {
      setLayoffForms(layoffFormFound);
    }
  };

  const handleNewWorkerSurveyId = (value) => {
    setWorkerSurveyId(value);
  };

  const fetchSurveyAnswers = async (values, surveyId) => {
    if (!surveyId || !layoffData?.worker?.id) {
      setSurveyData(values);
      return;
    }
    if (!workerSurveyId && surveyId) {
      setWorkerSurveyId(surveyId);
    }
    const res = await getSurveyAnswers(surveyId);
    const surveyAnswers = get(res, 'data.results', []);
    const newData = values?.map((question) => {
      const data = question;
      const foundQuestion = surveyAnswers?.filter((q) => q?.question?.id === question?.id);
      if (foundQuestion[0]?.question?.question_type === 'choice') {
        const foundIndex = question?.answers.findIndex((a) => a?.value === foundQuestion[0]?.answer);
        data.answer = foundQuestion[0].answer;
        data.answers[foundIndex].answer = true;
      }
      if (foundQuestion[0]?.question?.question_type === 'select') {
        if (foundQuestion.length > 1) {
          const answers = foundQuestion.map((q) => q.answer);
          data.answer = answers.join(',');
        } else {
          data.answer = foundQuestion[0].answer;
        }
      }
      if (foundQuestion[0]?.question?.question_type === 'number') {
        data.answer = foundQuestion[0].answer;
      }
      if (foundQuestion[0]?.question?.question_type === 'free_text') {
        data.text_answer = foundQuestion[0].text_answer;
      }
      if (foundQuestion[0]?.question?.question_type === 'date') {
        data.date_answer = foundQuestion[0].date_answer;
      }
      return data;
    });
    setSurveyData(newData);
  };

  const fetchQuestionsAndAnswers = async () => {
    const res = await getQuestions(layoffForms[0]?.id);
    const questionsData = get(res, 'data.results', []);
    await Promise.all(questionsData.map(async (data, i) => {
      const resp = await getQuestionOptions(data.id);
      const respData = get(resp, 'data.results', []);
      if (respData?.length) {
        questionsData[i].answers = respData;
      }
    }));
    let surveyFound;
    if (layoffData?.id || initialData?.id) {
      const resp = await getWorkerSurveys(layoffData?.worker?.id, companyId);
      const resData = get(resp, 'data.results', []);
      surveyFound = resData.find((f) => layoffForms.find((val) => f.survey.id === val.id));
      setWorkerSurveyData(surveyFound);
      if (!surveyFound) {
        setIsLoadingForm(false);
      }
    } else {
      setIsLoadingForm(false);
    }
    fetchSurveyAnswers(questionsData, surveyFound?.id);
  };

  useEffect(() => {
    fetchDocumentTypes();
    if (layoffData?.id) {
      fetchWorkerDocuments();
    }
    fetchForms();
  }, []);

  useEffect(() => {
    if (initialData?.id) {
      setIsLoadingForm(true);
      setIsLoadingDocuments(true);
      setLayoffData(initialData);
    }
    if (!initialData?.id) {
      setIsLoadingForm(false);
      setIsLoadingDocuments(false);
    }
  }, [layoffForms, initialData]);

  useEffect(() => {
    if (layoffData?.id) {
      fetchQuestionsAndAnswers();
      if (!selectedDocument?.length) {
        fetchWorkerDocuments();
      }
    }
  }, [layoffData]);

  useEffect(() => {
    setLayoffData(initialData || {});
  }, [initialData]);

  useEffect(() => {
    const newReasons = reasons.filter((r) => r.id !== 'all');
    setSelectReasons(newReasons);
  }, [reasons]);

  const handleAddReason = async () => {
    const data = {};
    data.company = companyId;
    data.name = newReasonInput;
    await addTerminationReasons(data);
    setShowAddReason(false);
    setNewReasonInput('');
    fetchReasons();
  };

  const handleNewReasonInput = (e) => {
    const value = e.target.value;
    setNewReasonInput(value);
  };

  const handleDocumentUpload = async (layoffId) => {
    await Promise.all(initialDocuments.map(async (document) => {
      const documentFound = selectedDocument.find((d) => d.id === document.id);
      if (!documentFound) {
        await deleteDocument(companyId, document.id);
      }
    }));
    await Promise.all(selectedDocument.map(async (document) => {
      if (document?.file?.name) {
        const documentName = document?.file?.name?.split('.').slice(0, -1).join('.');
        const formData = new FormData();
        formData.append('worker', layoffData?.worker?.id);
        formData.append('file', document.file);
        formData.append('user', currentUser?.id);
        formData.append('document_type', document?.document_type?.id);
        formData.append('name', documentName);
        formData.append('employment', layoffId);
        await uploadDocument(formData);
      }
      if (!document?.file?.name && (document?.document_type?.id !== initialDocuments?.document_type?.id)) {
        const newDocument = { document_type: document.document_type.id };
        await editDocument(newDocument, document.id, companyId);
      }
    }));
  };

  const handleCloseModal = () => {
    handleClose();
    setWorkerSurveyData(null);
    setShowFormModal(false);
    setSelectedDocument(null);
    setWorkerSurveyId(null);
    setLayoffData({});
    setSurveyData(null);
    setInitialDocuments([]);
    setDocumentsValid(true);
    setShowAddReason(false);
    setDocumentsUploaded(false);
  };

  const handleChangeData = (newData) => {
    setFormQuestions(newData);
  };

  const handleSave = async () => {
    if (selectedDocument?.length) {
      let documentTypesSelected = true;
      selectedDocument.forEach((d) => {
        if (!d.document_type) {
          documentTypesSelected = false;
        }
      });
      setDocumentsValid(documentTypesSelected);
      if (!documentTypesSelected) {
        return null;
      }
    }
    if (showAddReasons) {
      return null;
    }

    const data = layoffData;

    if (data?.worker) data.worker = data?.worker?.id;
    if (data?.termination_reason) data.termination_reason = data?.termination_reason?.id;
    if (data?.start_working) data.start_working = moment(data?.start_working).format(dateFormat);
    if (data?.end_working) data.end_working = moment(data?.end_working).format(dateFormat);

    let layoffId;

    if (layoffData?.id) {
      const res = await editEmployment(data, layoffData?.id, companyId);
      layoffId = res?.data?.id;
    } else {
      const res = await addEmployment(data);
      layoffId = res?.data?.id;
    }
    setLayoffData({});
    if ((selectedDocument?.length || initialDocuments?.length) && !documentsUploaded) {
      await handleDocumentUpload(layoffId);
    }
    let allAnswers = true;
    if (formQuestions.length) {
      formQuestions.forEach((q) => {
        if (!q.text_answer && q.question_type === 'free_text' && q.is_required) {
          allAnswers = false;
          return;
        }
        if (!q.answer && (q.question_type === 'choice' || q.question_type === 'select' || q.question_type === 'number') && q.is_required) {
          allAnswers = false;
        }
      });
      if (allAnswers && !workerSurveyData.is_finished) {
        await finishSurvey({ worker_survey: workerSurveyId });
      }
    }
    handleAddLayoff();
    handleCloseModal();
  };

  const handleSelectFile = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files).map((file) => ({
      file,
      name: file.name.slice(0, file.name.lastIndexOf('.')),
    }));
    const currentFiles = [];
    if (selectedDocument?.length) {
      currentFiles.push(...selectedDocument);
    }
    currentFiles.push(...newFiles);

    setSelectedDocument(currentFiles);
  };

  const handlePreviewForm = () => {
    const currentUrl = window.origin;
    const url = `${currentUrl}/surveys/worker-survey/${workerSurveyData?.id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleRemoveDocument = (row) => {
    const documents = [...selectedDocument];
    const newDocuments = documents.filter((d) => d.name !== row.original.name);
    setSelectedDocument(newDocuments);
  };

  const handleSelectDocumentType = (value, row) => {
    const documents = [...selectedDocument];
    const documentIndex = documents.findIndex((d) => d.name === row.original.name);
    if (documentIndex !== -1) {
      documents[documentIndex].document_type = value;
      setSelectedDocument(documents);
    }
  };

  const handleSaveAndFillButton = async () => {
    if (selectedDocument?.length) {
      let documentTypesSelected = true;
      selectedDocument.forEach((d) => {
        if (!d.document_type) {
          documentTypesSelected = false;
        }
      });
      setDocumentsValid(documentTypesSelected);
      if (!documentTypesSelected) {
        return null;
      }
    }
    setShowFormModal(true);
  };

  const fetchAssignedEquipment = () => {
    setAssignedEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = `&limit=60&order_by=-date_of_take&worker=${layoffData?.worker?.id}`;

    getAssignedEquipment(companyId, apiFilters)
      .then((res) => {
        setAssignedEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAssignedEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedAssignedEquipment = (url) => {
    setAssignedEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedAssignedEquipment(url)
      .then((res) => {
        setAssignedEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAssignedEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    if (layoffData?.worker?.id) fetchAssignedEquipment();
    else setAssignedEquipment({ data: [], next: null, previous: null, count: 0, isLoading: true });
  }, [layoffData?.worker]);

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_document_type')}</span>,
      accessor: 'document_type',
      Cell: (row) => <Select
        options={documentTypes}
        getOptionLabel={(reason) => reason.name}
        getOptionValue={(reason) => reason.id}
        menuPosition="fixed"
        placeholder={t('page_content.human_resources.layoffs.document_placeholder')}
        onChange={(value) => handleSelectDocumentType(value, row)}
        value={row.value || ''}
        styles={selectModalStyles}
      />,
    },
    {
      accessor: 'file',
      width: 50,
      sortable: false,
      Cell: (row) => (row && row.value ? <Button
        size="tiny"
        type="delete"
        onClick={() => handleRemoveDocument(row)}
      ><IconX width="16px" height="16px" /></Button> : '-'),
    },
  ];

  const equipmentStatus = [
    { value: 'taken', name: t('page_content.human_resources.assigned_equipment.status_taken') },
    { value: 'returned', name: t('page_content.human_resources.assigned_equipment.status_returned') },
    { value: 'lost', name: t('page_content.human_resources.assigned_equipment.status_lost') },
    { value: 'damaged', name: t('page_content.human_resources.assigned_equipment.status_damaged') },
  ];

  const renderAssignedEquipmentTable = () => {
    return (<div className="layoff_form_assigned_equipment">
      <div style={{ padding: '20px 0', fontSize: '14px', fontWeight: 600 }}>
        {t('page_content.human_resources.assigned_equipment_tab')}
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_name')}</span>,
            accessor: 'name',
            Cell: (row) => (row?.value ? row.value : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_quantity')}</span>,
            accessor: 'quantity',
            width: 100,
            Cell: (row) => (row?.value ? row.value : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_taken')}</span>,
            accessor: 'date_of_take',
            width: 100,
            Cell: (row) => (row?.value ? moment(row.value, 'YYYY-MM-DD').format(defaultDateFormat) : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_returned')}</span>,
            accessor: 'date_of_return',
            width: 100,
            Cell: (row) => (row?.value ? moment(row.value, 'YYYY-MM-DD').format(defaultDateFormat) : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_note')}</span>,
            accessor: 'note',
            width: 300,
            Cell: (row) => (row?.value ? row.value : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_status')}</span>,
            accessor: 'status',
            Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={row?.value && equipmentStatus?.find((val) => val.value === row?.value)?.name ? styledAssignedEquipmentStatusOptions(row?.value) : {}}>
                {row?.value ? equipmentStatus?.find((val) => val.value === row?.value)?.name : '-'}</span>
            </div>,
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_worker')}</span>,
            accessor: 'worker',
            Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_take_clerk')}</span>,
            accessor: 'take_clerk',
            Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_return_clerk')}</span>,
            accessor: 'return_clerk',
            Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
        ]}
        minRows={0}
        noDataText=""
        selectedRow={null}
        defaultPageSize={100}
        showPagination={false}
        data={assignedEquipment?.data || []}
        loading={assignedEquipment?.isLoading}
        defaultSorted={[{ id: 'date_of_take', desc: true }]}
      />
      <div>
        <span style={{ float: 'right', padding: '10px 0' }}>
          <TableButtons
            next={assignedEquipment?.next}
            count={assignedEquipment?.count}
            previous={assignedEquipment?.previous}
            fetchFunction={fetchPaginatedAssignedEquipment}
          />
        </span>
      </div>
    </div>);
  };

  return (
    <Modal
      title={showAddReasons ? t('page_content.human_resources.layoffs.modal_title_reason') : t('page_content.human_resources.layoffs.modal_title_layoff')}
      disableSave={showAddReasons || !layoffData?.worker || !layoffData?.start_working}
      isOpen={showModal}
      size={modalSizes.fullPlus}
      handleClose={handleCloseModal}
      handleSave={handleSave}
      error={!documentsValid && t('page_content.human_resources.layoffs.select_document_types_error')}
      enableSaveOnEnter={false}
    >
      <div className="layoff_modal_container">
        {!showFormModal ? <div>
          {initialData?.id && <Button type={showAddReasons ? 'dark-blue' : 'add'} onClick={() => setShowAddReason((prevState) => !prevState)}>
            {showAddReasons ? t('page_content.human_resources.layoffs.button_back') : t('page_content.human_resources.layoffs.button_add_reason')}
          </Button>}
          {showAddReasons ? <div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.layoffs.label_reason')}</div>
              <div className="right_input">
                <input value={newReasonInput} onChange={handleNewReasonInput} placeholder={t('page_content.human_resources.layoffs.placeholder_type_reason')} />
              </div>
            </div>
            <div className="save_reason_button">
              <Button type="success" onClick={handleAddReason}>{t('page_content.human_resources.layoffs.button_add_reason')}</Button>
            </div>
          </div> :
            <div>

              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.layoffs.label_worker')} *</div>
                <div className="right_select">
                  <Select
                    options={workers}
                    getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                    getOptionValue={(option) => option.id}
                    menuPosition="fixed"
                    placeholder={t('page_content.human_resources.layoffs.placeholder_worker')}
                    onChange={(value) => handleChange(value, 'worker')}
                    value={workers?.find((w) => w.id === layoffData?.worker?.id) || ''}
                    styles={selectModalStyles}
                  />
                </div>
              </div>
              {
                initialData?.id &&
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.layoffs.label_reason')}</div>
                  <div className="right_select">
                    <Select
                      options={selectReasons}
                      getOptionLabel={(reason) => reason.name}
                      getOptionValue={(reason) => reason.id}
                      menuPosition="fixed"
                      placeholder={t('page_content.human_resources.layoffs.placeholder_reason')}
                      onChange={(value) => handleChange(value, 'termination_reason')}
                      value={(selectReasons && selectReasons?.find((r) => r.id === layoffData?.termination_reason?.id)) || ''}
                      styles={selectModalStyles}
                    />
                  </div>
                </div>
              }

              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.layoffs.table_column_working_start')} *</div>
                <DatePicker
                  className="datepicker_input"
                  placeholderText={t('page_content.human_resources.layoffs.table_column_working_start')}
                  dateFormat="dd.MM.yyyy"
                  selected={layoffData?.start_working ? new Date(layoffData?.start_working) : null}
                  selectsStart
                  onChange={(date) => handleChange(date, 'start_working')}
                  locale={getLocale(t)}
                />
              </div>

              {
                initialData?.id &&
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.layoffs.label_layoff_date')} *</div>
                  <DatePicker
                    className="datepicker_input"
                    placeholderText={t('page_content.orders.end_date_placeholder')}
                    dateFormat="dd.MM.yyyy"
                    selected={layoffData?.end_working ? new Date(layoffData?.end_working) : null}
                    selectsStart
                    onChange={(date) => handleChange(date, 'end_working')}
                    locale={getLocale(t)}
                  />
                </div>
              }

              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.layoffs.label_comment')}</div>
                <div className="right_textarea">
                  <textarea value={layoffData?.note} onChange={(e) => handleChange(e.target.value, 'note')} />
                </div>
              </div>

              <div className="modal_row upload_footer">
                <div className="left_text">{t('page_content.human_resources.layoffs.label_upload_document')}</div>
                <div className="right_textarea">
                  <div className="upload_footer_content">
                    <input type="file" multiple onChange={handleSelectFile} />
                    {isLoadingDocuments ?
                      <ContentLoader text="" rowVariant /> : selectedDocument?.length ?
                        <Table
                          style={{ userSelect: 'text' }}
                          columns={tableColumns}
                          data={selectedDocument || []}
                          noDataText=" "
                          showPagination={false}
                          minRows={0}
                          sortable={false}
                          defaultPageSize={30}
                          selectedRow={null}
                        /> : ''}
                  </div>
                </div>
              </div>

              {
                initialData?.id &&
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.layoffs.label_layoff_form')}</div>
                  <div className="right_select">
                    {isLoadingForm ? <ContentLoader rowVariant text="" /> :
                      <div>
                        <Button
                          disabled={workerSurveyData?.is_finished || !layoffData?.worker || !layoffData?.end_working}
                          onClick={handleSaveAndFillButton}
                        >{layoffData?.id ? t('page_content.human_resources.layoffs.button_fill_form') : t('page_content.human_resources.layoffs.button_fill_and_save_form')}</Button>
                        {workerSurveyData?.is_finished && <span style={{ marginLeft: 20 }}>
                          <Button onClick={handlePreviewForm}>{t('page_content.human_resources.layoffs.preview_form_button')}</Button>
                          <span className="form_finished_message">{t('page_content.human_resources.layoffs.form_filled_message')}</span></span>}
                      </div>}
                  </div>
                </div>
              }

              {layoffData?.worker && initialData?.id && renderAssignedEquipmentTable()}
            </div>}</div> :
          <div>
            <Button type="dark-blue" onClick={() => setShowFormModal(false)}>{t('page_content.human_resources.layoffs.button_back')}</Button>
            <FormLayoffModal
              currentUser={currentUser}
              companyId={companyId}
              formData={layoffForms[0]}
              surveyData={surveyData}
              workerSurveyId={workerSurveyId}
              handleChange={handleChangeData}
              workerId={layoffData?.worker?.id}
              handleNewWorkerSurveyId={handleNewWorkerSurveyId}
            />
          </div>}
      </div>
    </Modal>
  );
};

AddLayoffModal.propTypes = {
  companyId: PropTypes.number.isRequired,
  showModal: PropTypes.bool,
  t: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleAddLayoff: PropTypes.func,
  workers: PropTypes.array,
  currentUser: PropTypes.object,
  reasons: PropTypes.array,
  fetchReasons: PropTypes.func,
  initialData: PropTypes.object,
  layoffCategoryCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    layoffCategoryCode: get(state, 'app.location.config.layoff_category_code', ''),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(AddLayoffModal)));
