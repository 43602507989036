import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { get } from 'lodash';
import moment from 'moment';

import { IconEdit, IconSearch } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { defaultDateFormat } from 'shared/constants';
import { Table, TableButtons, Button } from 'shared';
import api from 'helpers/api';
import { selectModalStyles } from 'styles/modules/reactSelect';
import WorkerModalForm from './WorkerModalForm';
import { getWorkersList, getPaginatedWorkersList, putWorker, getAllDepartments, getPartners } from '../../actions';

const WorkersList = ({ locationId, history, t, companyId, customDepartment, department, userHaveFullAccess = false }) => {
  const debounceTimeoutRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [tableData, setTableData] = useState({
    data: [],
    count: null,
    next: null,
    previous: null,
    isLoading: true,
  });

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    activityFilter: '',
    departmentFilter: department,
    companiesFilter: '',
    selectedSearchBy: { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') },
    selectedAscDesc: 'desc',
    selectedSort: 'active_from',
  });

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const fetchWorkers = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        urlFilters += `&first_or_last_name=${query}`;
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${filters?.selectedSort}`;

    getWorkersList(locationId, companyId, urlFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', null),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoading: false,
        });
      })
      .catch((e) => console.error('Error while fetching workers', e));
  };

  const fetchDepartments = async () => {
    const res = await getAllDepartments(locationId);
    setDepartmentOptions(get(res, 'data.results', []));
  };

  const fetchCompanies = async () => {
    const res = await getPartners(companyId);
    setCompaniesOptions(get(res, 'data.results', []));
  };

  useEffect(() => {
    fetchDepartments();
    fetchCompanies();
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [filters]);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchWorkers();
      }, 300);
    }
  }, [query]);

  const fetchPaginatedWorkersList = (url) => {
    getPaginatedWorkersList(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', null),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoading: false,
        });
      })
      .catch((e) => console.error('Error while fetching documents', e));
  };

  const redirectToWorkerDetails = (row) => {
    const workerId = row.original.id || null;
    history.push(`/${companyId}/industry/location/${locationId}/human-resources/worker/${workerId}`);
  };

  const transformNameOrSurnameString = (string) => {
    if (!string) {
      return '';
    }
    const deconstructedString = string.split(/\s+|-/);
    const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

    return formatedString;
  };

  const clearAllFilters = () => {
    setQuery('');
    setFilters((prevState) => ({
      ...prevState,
      activityFilter: '',
      departmentFilter: '',
      companiesFilter: '',
      selectedSearchBy: { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') },
    }));
  };

  const handleEditButton = (e, row) => {
    e.stopPropagation();
    setShowModal(true);
    setData(row.original);
    setNewData({
      name: row.original.name,
      last_name: row.original.last_name,
      company: row.original.company,
    });
  };

  const handleDataChange = (value, key) => {
    setNewData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSave = async (callback) => {
    try {
      if (!data) {
        setTableData((prevState) => ({ ...prevState, isLoading: true }));

        if (newData.profile_picture) {
          // ----------------------------- Creating a new worker with a profile picture! -----------------------------

          const formData = new FormData();
          formData.append('location', locationId);
          formData.append('company', companyId);

          for (const key in newData) {
            if (key !== 'locationId' && key !== 'companyId') {
              if (key === 'location_department' || key === 'working_place' || key === 'qualification' || key === 'profession') {
                formData.append(key, newData[key].id);
              } else {
                formData.append(key, newData[key]);
              }
            }
          }

          await api.post(`/api/v1/workforce/workers/${newData.create_user ? '?create_user=true' : ''}`, formData);
        } else {
          // ----------------------------- Creating a new worker without a profile picture! -----------------------------

          const postData = { ...newData, company: companyId, location: locationId };
          if (newData?.profession?.id) postData.profession = newData.profession.id;
          if (newData?.qualification?.id) postData.qualification = newData.qualification.id;
          if (newData?.working_place?.id) postData.working_place = newData.working_place.id;
          if (newData?.location_department?.id) postData.location_department = newData.location_department.id;
          if (newData?.department_shift) postData.department_shift = typeof newData.department_shift === 'object' ? newData.department_shift.id : newData.department_shift;

          postData.is_cooperant = newData?.is_cooperant || false;
          if (newData?.is_cooperant && newData?.partner?.id) postData.partner = newData.partner.id;
          else postData.partner = null;

          postData.work_permit = newData?.work_permit || false;
          if (newData?.work_permit && newData?.work_permit_valid_until) postData.work_permit_valid_until = moment(newData.work_permit_valid_until).format('YYYY-MM-DD');
          else if (!newData?.work_permit) postData.work_permit_valid_until = null;

          await api.post(`/api/v1/workforce/workers/${newData.create_user ? '?create_user=true' : ''}`, postData);
        }

        setShowModal(false);
        fetchWorkers();
        setData(null);
      } else {
        setTableData((prevState) => ({ ...prevState, isLoading: true }));

        if (!newData?.profile_picture) {
          // ----------------------------- Updating already created worker without a picture -----------------------------

          const newObject = { ...newData };
          if (newData?.profession) newObject.profession = newData.profession.id;
          if (newData?.qualification) newObject.qualification = newData.qualification.id;
          if (newData?.working_place) newObject.working_place = newData.working_place.id;
          if (newData?.location_department) newObject.location_department = newData.location_department.id;
          if (newData?.department_shift) newObject.department_shift = typeof newData.department_shift === 'object' ? newData.department_shift.id : newData.department_shift;

          newObject.is_cooperant = typeof newData?.is_cooperant !== 'boolean' ? data?.is_cooperant : newData.is_cooperant || false;
          if (newObject.is_cooperant && newData?.partner?.id) newObject.partner = newData.partner.id;
          else if (!newObject.is_cooperant) newObject.partner = null;

          newObject.work_permit = typeof newData?.work_permit !== 'boolean' ? data?.work_permit : newData.work_permit || false;
          if (newObject.work_permit && newData?.work_permit_valid_until) newObject.work_permit_valid_until = moment(newData.work_permit_valid_until).format('YYYY-MM-DD');
          else if (!newObject.work_permit) newObject.work_permit_valid_until = null;

          await putWorker(newObject, companyId, locationId, data.id);
        } else {
          // ----------------------------- Updating already created worker with a picture! -----------------------------

          const formData = new FormData();
          for (const key in newData) {
            if (key !== 'locationId' && key !== 'companyId') {
              if (key === 'location_department' || key === 'working_place' || key === 'qualification' || key === 'profession') {
                formData.append(key, newData[key].id);
              } else {
                formData.append(key, newData[key]);
              }
            }
          }

          await putWorker(formData, companyId, locationId, data.id);
        }
        setShowModal(false);
        fetchWorkers();
        setData(null);
      }
      if (callback) callback(null);
    } catch (error) {
      if (callback) callback(error.message);
    } finally {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setData(null);
  };

  const handleAddNewWorker = () => {
    setShowModal(true);
    setData(null);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'location_department.name') {
      column = 'location_department';
    }
    if (column === 'working_place.name') {
      column = 'working_place__name';
    }
    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: column,
    }));
  };

  const tableColumnConfig = [
    {
      Header: () => <span>PIN</span>,
      accessor: 'external_id',
      width: 100,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_first_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_last_name')}</span>,
      accessor: 'last_name',
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    // {
    //   Header: () => <span>{t('page_content.human_resources.workers.table_column_email')}</span>,
    //   accessor: 'email',
    //   Cell: (row) => (row.value ? row.value : '-'),
    // },
    // {
    //   Header: () => <span>{t('page_content.human_resources.workers.table_column_private_email')}</span>,
    //   accessor: 'private_email',
    //   Cell: (row) => (row.value ? row.value : '-'),
    // },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_department')}</span>,
      accessor: `${customDepartment}.name` || 'department',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.default_shift')}</span>,
      accessor: 'department_shift',
      Cell: (row) => (row.value ? `${row?.value?.name} (${moment(row?.value?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(row?.value?.end_time, 'HH:mm:ss').format('HH:mm')})` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_working_place')}</span>,
      accessor: 'working_place.name',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_company')}</span>,
      accessor: 'partner',
      width: 150,
      Cell: (row) => (row.value ? row.value.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active_from')}</span>,
      accessor: 'active_from',
      width: 120,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active_to')}</span>,
      accessor: 'active_to',
      width: 120,
      Cell: (row) => (row.original?.is_active ? '-' : row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.input_label_work_permit')}</span>,
      accessor: 'work_permit_valid_until',
      width: 120,
      Cell: (row) => (!row.original?.work_permit ? '-' : row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active')}</span>,
      accessor: 'is_active',
      width: 75,
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_edit')}</span>,
      width: 75,
      style: {
        cursor: 'default',
      },
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleEditButton(e, row)} disabled={!userHaveFullAccess}>
          <IconEdit
            height="14px"
            width="14px"
            fill="#4285F4"
          />
        </Button>
      </div>),
    },
  ];

  const activeFilterOptions = [
    { id: 'all', name: t('page_content.human_resources.workers.activity_option_all') },
    { id: 'active', name: t('page_content.human_resources.workers.activity_option_active') },
    { id: 'inactive', name: t('page_content.human_resources.workers.activity_option_inactive') }];
  const searchByOptions = [
    { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') },
    { id: 'email', name: t('page_content.human_resources.workers.search_by_option_email') },
    { id: 'private_email', name: t('page_content.human_resources.workers.search_by_option_private_email') },
    { id: 'address', name: t('page_content.human_resources.workers.search_by_option_address') },
  ];

  return (
    <div className="workersList_container">
      <div className="workersList_addNew">
        <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
            <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.orders.search_for_placeholder')} value={query || ''} />
            {query && <button
              onClick={() => setQuery('')}
            >&times;</button>}
              </div>
              <Select
                className="select-style"
                options={searchByOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(e) => handleFilterChange('selectedSearchBy', e)}
                value={filters?.selectedSearchBy}
                styles={selectModalStyles}
              />
          <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          </div>
          <div className="filters">
          <Select
            className="select-style"
            options={activeFilterOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_activity_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('activityFilter', e)}
            value={activeFilterOptions.find((a) => a.id === filters?.activityFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_department_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('departmentFilter', e)}
            value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={companiesOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_company_placeholder')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('companiesFilter', e)}
            value={companiesOptions.find((a) => a.id === filters?.companiesFilter?.id) || ''}
            styles={selectModalStyles}
          />
           <div className="clear_button">
              <Button
                type="plain"
                onClick={clearAllFilters}
              >
                {t('page_content.orders.clear_all_button')}
              </Button>
            </div>
        <div className="add_worker_button">
          <Button type="add" onClick={handleAddNewWorker} disabled={!userHaveFullAccess}>{t('page_content.human_resources.workers.button_add_new_worker')}</Button>
        </div>
        </div>
      </div>
      <div className="workersList_tableArea">
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={tableData.data}
          minRows={0}
          defaultPageSize={40}
          noDataText=" "
          showPagination={false}
          loading={tableData.isLoading}
          defaultSorted={[{ id: 'active_from', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo) {
              const workPermitValidUntil = moment(rowInfo.original.work_permit_valid_until);
              const fortyDaysFromToday = moment().add(40, 'days');
              const isWithinFortyDays = workPermitValidUntil.isSameOrBefore(fortyDaysFromToday) && workPermitValidUntil.isAfter(moment());

              return {
                style: { backgroundColor: isWithinFortyDays ? 'rgb(239, 195, 195)' : null },
                onClick: () => { redirectToWorkerDetails(rowInfo); },
              };
            }
          }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedWorkersList}
          count={tableData.count}
        />
      </div>
      {showModal &&
        <WorkerModalForm
          initialData={data}
          companiesOptions={companiesOptions}
          setData={handleDataChange}
          locationId={locationId}
          t={t}
          handleSave={handleSave}
          handleClose={handleClose}
          showModal={showModal}
          customDepartment={customDepartment}
          companyId={companyId}
          departments={departmentOptions}
        />}
    </div>
  );
};

WorkersList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  customDepartment: PropTypes.string,
  department: PropTypes.number,
  userHaveFullAccess: PropTypes.bool,
};

export default (withRouter(withTranslation()(WorkersList)));
