import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get, update } from 'lodash';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { IconX } from 'shared/Icons';
import { getLocale } from 'shared/DatePicker/constants';
import { Modal, ContentLoader } from 'shared';
import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { deleteUploadedFile, finishSurvey, getQuestionOptions, getQuestions, getSurveyAnswers, getUploadedFiles, newWorkerSurvey, saveSurveyFiles, saveWorkerAnswer } from './actions';
import './styles.scss';

const FormLayoffModal = ({ formData, t, surveyData, workerSurveyId, handleChange, workerId, handleNewWorkerSurveyId }) => {
  const debounceTimeoutRef = useRef(null);
  const [formQuestions, setFormQuestions] = useState(surveyData || []);
  const [isLoading, setIsLoading] = useState(true);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [surveyId, setSurveyId] = useState(workerSurveyId || null);

  useEffect(() => {
    if (workerSurveyId) {
      setSurveyId(workerSurveyId);
    }
  }, [workerSurveyId]);

  useEffect(() => {
    if (formQuestions.length) {
      setIsLoading(false);
    }
  }, [formQuestions]);

  useEffect(() => {
    if (surveyData?.length) {
      setFormQuestions(surveyData);
    }
  }, [surveyData]);

  const createWorkerSurvey = async () => {
    const data = {
      worker: workerId,
      survey: formData.id,
    };
    const res = await newWorkerSurvey(data);
    return res?.data?.id;
  };

  const handleChangeChoice = async (answerValue, index, question) => {
    const questionFound = formQuestions.find((q) => q.id === question.id);
    let answerId = null;

    let workerSurvey = surveyId;
    if (!workerSurvey) {
      const res = await createWorkerSurvey();
      workerSurvey = res;
      setSurveyId(workerSurvey);
      handleNewWorkerSurveyId(workerSurvey)
    }
    
    const updatedAnswers = await Promise.all(questionFound.answers.map(async (a, i) => {
      const data = {};
      const ans = a;
      if (i === index) {
        data.worker_survey = workerSurvey;
        data.question = question?.id;
        data.answer = answerValue;
        const res = await saveWorkerAnswer(data);
        ans.answer = true;
        answerId = a.id;
      } else {
        ans.answer = false;
      }
      return ans
    }));
    const updatedFormQuestions = formQuestions?.map((q) => {
      if (q.id === question.id) {
        return { ...q, answer: answerId, answers: updatedAnswers };
      }
      return q;
    });
    setFormQuestions(updatedFormQuestions);
    handleChange(updatedFormQuestions);
  }

  const handleInputChange = async (value, question) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    const updatedQuestions = [...formQuestions];
    const foundQuestion = updatedQuestions.find((q) => q.id === question.id);

    let workerSurvey = surveyId;
    if (!workerSurvey) {
      const res = await createWorkerSurvey();
      workerSurvey = res;
      setSurveyId(workerSurvey);
      handleNewWorkerSurveyId(workerSurvey)
    }
    const data = {};
    data.worker_survey = workerSurvey;
    data.question = question?.id;
    data.answer = value;
    if (foundQuestion && question.question_type === 'free_text') {
      foundQuestion.text_answer = value;
    }
    if (foundQuestion && question.question_type === 'number') {
      foundQuestion.answer = value;
    }
    if (question.question_type === 'number' &&
    (question.number_limit_from || question.number_limit_to) &&
    (value < question.number_limit_from || value > question.number_limit_to)) {
      foundQuestion.number_not_valid = true;
      setFormQuestions(updatedQuestions);
      return
    }
    else {
      foundQuestion.number_not_valid = false;
      setFormQuestions(updatedQuestions);
    }

    debounceTimeoutRef.current = setTimeout( async () => {
      const res = await saveWorkerAnswer(data);
    }, 1000);
    setFormQuestions(updatedQuestions);
    handleChange(updatedQuestions);
  }

  const handleDateChange = async (value, question) => {
    setSelectedDate(value);
    let workerSurvey = surveyId;
    if (!workerSurvey) {
      const res = await createWorkerSurvey();
      workerSurvey = res;
      setSurveyId(workerSurvey);
      handleNewWorkerSurveyId(workerSurvey)
    }
    const updatedQuestions = [...formQuestions];
    const foundQuestion = updatedQuestions.find((q) => q.id === question.id);
    const data = {};
    data.worker_survey = workerSurvey;
    data.question = question?.id;
    data.answer = moment(value).format("YYYY-MM-DD");
    foundQuestion.date_answer = value;
    const res = await saveWorkerAnswer(data);
    handleChange(updatedQuestions);
  } 

  const handleSelectChange = async (values, question) => {
    const data = {};
    let workerSurvey = surveyId;
    if (!workerSurvey) {
      const res = await createWorkerSurvey();
      workerSurvey = res;
      setSurveyId(workerSurvey);
      handleNewWorkerSurveyId(workerSurvey)
    }
    const answers = values?.length ? values?.map((val) => val.value).join(",") : '';
    if (values?.length) {
      data.worker_survey = workerSurvey;
      data.question = question?.id;
      data.answer = answers;
      await saveWorkerAnswer(data);
    }
    const foundIndex = formQuestions.findIndex((q) => q?.id === question?.id);
    const updatedQuestions = [...formQuestions];
    if (foundIndex !== -1) {
        updatedQuestions[foundIndex].answer = answers || '';
    }
    setFormQuestions(updatedQuestions);
    handleChange(updatedQuestions);
  }

  return (
    <div>
        {isLoading ? <ContentLoader text="" /> :
        <div className="form_modal_content">
          {formData?.description && 
          <div className="form_modal_description">
            {formData?.description}
          </div>}
            {formQuestions?.length && formQuestions?.map((q) => {
              return <div className="question_forms_container" key={q.id}>
                <div className="question_title">
                    <div className="question_index">{q.order}</div>
                    <div className="question_question">{q.question}</div>
                    <div className="question_is_required">{q?.is_required ?
                      t('page_content.human_resources.forms.answer_required') :
                      t('page_content.human_resources.forms.answer_optional')}
                    </div>
                </div>
                <div className="question_answer_content">
                    {q?.answers?.length && (q?.question_type === 'choice' || q?.question_type === 'select') ? <div>
                        {q?.question_type === 'choice' && <div>
                                {q.answers.map((a, i) => {
                                  return <div key={a.id} className="radio_answer_inline">
                                        <div
                                          className="radio_input">
                                            <input
                                              disabled={formData?.is_finished}
                                              checked={a.answer || false}
                                              onChange={() => handleChangeChoice(a.value, i, q)}
                                              type="radio"
                                            />
                                          </div>
                                        <span className="checkbox_label">{a.label}</span>
                                    </div>;
                                })}
                            </div>}
                        {q?.question_type === 'select' && <div>
                        <Select
                          options={q.answers}
                          getOptionLabel={(type) => type.label}
                          getOptionValue={(type) => type.value}
                          placeholder={t('page_content.human_resources.forms.placeholder_select_answers')}
                          menuPosition="fixed"
                          onChange={(value) => handleSelectChange(value, q)}
                          isDisabled={formData?.is_finished}
                          isSearchable
                          isMulti
                          value={q?.answers?.filter((a) => q?.answer?.split(',').some((ans) => a.value == ans)) || ''}
                          styles={multiSelectStylesAutoHeight}
                        />
                        </div>}
                    </div> :
                    q?.question_type === 'date' ?
                    <div className="date_field_answer">
                      <DatePicker
                        className="datepicker_input"
                        placeholderText={t('page_content.human_resources.forms.placeholder_date_picker')}
                        dateFormat="dd.MM.yyyy"
                        selected={q.date_answer ? new Date(q.date_answer) : null}
                        disabled={formData?.is_finished}
                        onChange={(date) => handleDateChange(date, q)}
                        locale={getLocale(t)}
                      />
                  </div> :
                  <div className="question_answer_content-input">
                  <input
                  type={`${q?.question_type}`}
                  disabled={formData?.is_finished}
                  value={q?.question_type === 'number' ? q.answer : q.text_answer}
                  onChange={(e) => handleInputChange(e.target.value, q)}
                  />
                  {q.number_not_valid &&
                    <div className="validation_number_error">
                      {`${t('page_content.human_resources.forms.number_not_in_range')}
                       (${q.number_limit_from}-${q.number_limit_to})`}</div>
                  }
                  </div>}
                </div>
                </div>;
            })}
        </div>}
    </div>
  );
};

FormLayoffModal.propTypes = {
  formData: PropTypes.object,
  workerId: PropTypes.number,
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  surveyData: PropTypes.object,
  workerSurveyId: PropTypes.number,
  handleNewWorkerSurveyId: PropTypes.func,
};

export default (withTranslation()(FormLayoffModal));
