import moment from 'moment';

export const defaultHeaderFormats = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY',
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'dddd, LL',
    mediumLong: 'dddd, LL',
    medium: 'dd D',
    short: 'D',
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
  second: {
    long: 'mm:ss',
    mediumLong: 'mm:ss',
    medium: 'mm:ss',
    short: 'ss',
  },
};

export const processWorkerSchedules = (data) => {
  return Object.keys(data).map((date) => {
    const item = data[date];
    const startTime = item?.begin_time
      ? moment(`${date} ${item?.begin_time}`).toDate()
      : moment(date).startOf('day').toDate();
    let endTime = item?.end_time
      ? moment(`${date} ${item?.end_time}`).toDate()
      : moment(date).endOf('day').toDate();

    if (endTime < startTime) {
      endTime = moment(endTime).add(1, 'day').toDate();
    }

    return {
      id: date,
      group: 1,
      date,
      start_time: startTime,
      end_time: endTime,
      day_type: item?.day_type,
    };
  });
};
