import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { IconPopup } from 'shared/Icons';
import { Table, TableButtons, Button } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { getProductionRecordsByLocation, getPaginatedProductionRecords } from '../actions';

const RecordsList = ({ t, locationId, handleTabSelect, setFilters }) => {
  const [tablesData, setTablesData] = useState({
    isLoading: true,
    data: [],
    next: null,
    previous: null,
  });
  const [selectedSort, setSelectedSort] = useState('time_updated');
  const [asc, setAsc] = useState('-');

  const getTableData = () => {
    setTablesData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const column = selectedSort;
    let filters = `&order_by=${asc}${column}`;
    if (column === 'production_record.title' ||
      column === 'line.name' ||
      column === 'production_record.signatory_1_title' ||
      column === 'production_record.signatory_2_title' ||
      column === 'production_record.signatory_3_title'
    ) {
      filters = `&order_by=${asc}${column.replace('.', '__')}`;
    }
    if (column === 'order') {
      filters = `&order_by=${asc}order__customer_name`;
    }

    getProductionRecordsByLocation(locationId, filters)
      .then((re) => {
        setTablesData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  useEffect(() => {
    getTableData();
  }, [selectedSort, asc]);

  const getPaginatedTableData = (url) => {
    setTablesData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedProductionRecords(url)
      .then((re) => {
        setTablesData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const handleOpenButton = (e, row) => {
    e.preventDefault();

    handleTabSelect(1);

    const filters = {
      selectedRecord: get(row, 'original.production_record.id', null),
      selectedLine: get(row, 'original.line.code', null),
      dateFilterStart: get(row, 'original.time_updated', moment()),
      dateFilterEnd: get(row, 'original.time_updated', moment()),
      orderExtIdFilterQuery: get(row, 'original.order.external_id', null),
    };
    setFilters(filters);
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    const sortOrder = sortData.desc ? '-' : '';
    setSelectedSort(column);
    setAsc(sortOrder);
  };

  return (
    <div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.production_records.time')}</span>,
              accessor: 'time_updated',
              Cell: (row) => (row?.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.production_records.title')}</span>,
              accessor: 'production_record.title',
              Cell: (row) => (row?.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.production_records.line')}</span>,
              accessor: 'line.name',
              Cell: (row) => (row?.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.production_records.order')}</span>,
              accessor: 'order',
              Cell: (row) => <span style={{ display: 'inline-block' }}>
              <strong>{row?.value?.external_id || ''}</strong>
              <br />
              <small>{row?.value?.customer_name || ''}</small>
            </span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.production_records.sig_1_title')}</span>,
              accessor: 'production_record.signatory_1_title',
              Cell: (row) => (row?.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.production_records.sig_2_title')}</span>,
              accessor: 'production_record.signatory_2_title',
              Cell: (row) => (row?.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.production_records.sig_3_title')}</span>,
              accessor: 'production_record.signatory_3_title',
              Cell: (row) => (row?.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              width: 100,
              sortable: false,
              Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleOpenButton(e, row)}>
                  <IconPopup
                    height="14px"
                    width="14px"
                    fill="#4285F4"
                  />
                </Button>
              </div>),
            },
          ]}
          data={tablesData.data || []}
          loading={tablesData.isLoading}
          noDataText=" "
          defaultPageSize={30}
          showPagination={false}
          minRows={0}
          defaultSorted={[{ id: 'time_updated', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons
            next={tablesData.next}
            previous={tablesData.previous}
            fetchFunction={getPaginatedTableData}
            count={tablesData.count}
          />
        </div>
    </div>
  );
};

RecordsList.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  handleTabSelect: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default RecordsList;
