import React from 'react';

import { Button } from 'shared';
import '../../../styles.scss';

const PersonnelDataRating = () => {
  return (
    <div className="ratings_screen">
      <span className="add_record_title">Personnel Data Rating</span>

      <div className="custom_inputs_view">
        <div className="modal_row">
          <div className="left_text">
            <label>Label</label>
          </div>

          <div className="right_input">
              <input type="text" />
            </div>
        </div>

        <div className="modal_row">
          <div className="left_text">
            <label>Label2</label>
          </div>

          <div className="right_input">
              <input type="text" />
            </div>
        </div>

        <div className="modal_row">
          <div className="left_text">
            <label>Label3</label>
          </div>

          <div className="right_input">
              <input type="text" />
            </div>
        </div>
      </div>
      <div className="save_button">
        <Button type="success">Save</Button>
      </div>
    </div>
  );
};

export default PersonnelDataRating;
