/* eslint-disable no-dupe-keys */
import moment from 'moment';
import { numberSeparatorFormat } from 'industry/helpers';
import i18n from 'i18next';

export const getStyleByStatus = (status, quantity, isManualEntry, altStatus) => {
  quantity = quantity > 100 ? 100 : quantity;
  switch (status) {
    case 'scheduled':
      return {
        background: 'rgba(113, 125, 152, 0.7)',
        background: `-webkit-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(113, 125, 152, 0.7) ${quantity}%)`,
        background: `-moz-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%, rgba(113, 125, 152, 0.7) ${quantity}%)`,
        background: `-ms-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(113, 125, 152, 0.7) ${quantity}%)`,
        background: `-o-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(113, 125, 152, 0.7) ${quantity}%)`,
        background: `linear-gradient(to right, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(113, 125, 152, 0.7) ${quantity}%)`,
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid #717d98',
        color: '#fff',
        overflow: 'hidden',
      };
    case 'in_progress':
      return {
        background: 'rgba(95, 169, 238, 0.7)',
        background: `-webkit-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(95, 169, 238, 0.7) ${quantity}%)`,
        background: `-moz-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%, rgba(95, 169, 238, 0.7) ${quantity}%)`,
        background: `-ms-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(95, 169, 238, 0.7) ${quantity}%)`,
        background: `-o-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(95, 169, 238, 0.7) ${quantity}%)`,
        background: `linear-gradient(to right, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(95, 169, 238, 0.7) ${quantity}%)`,
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid #5fa9ee',
        color: '#fff',
        overflow: 'hidden',
      };
    case 'finished':
      return {
        background: 'rgba(80, 150, 59, 0.7)',
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid #50963b',
        color: '#fff',
        overflow: 'hidden',
      };
    case 'paused':
      return {
        background: 'rgba(175, 122, 179, 0.7)',
        background: `-webkit-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(175, 122, 179, 0.7) ${quantity}%)`,
        background: `-moz-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%, rgba(175, 122, 179, 0.7) ${quantity}%)`,
        background: `-ms-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(175, 122, 179, 0.7) ${quantity}%)`,
        background: `-o-linear-gradient(left, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(175, 122, 179, 0.7) ${quantity}%)`,
        background: `linear-gradient(to right, rgba(80, 150, 59, 0.7) ${quantity}%,rgba(175, 122, 179, 0.7) ${quantity}%)`,
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid #AF7AB3',
        color: '#222',
        overflow: 'hidden',
      };
    case 'switchover':
      return {
        background: altStatus === 'scheduled' ? '#D1C985' : altStatus === 'in_progress' ? '#FFEB3B' : '#CABA32',
        border: 'none',
        color: '#222',
        overflow: 'hidden',
      };
    case 'product_trial':
      return {
        background: altStatus === 'scheduled' ? '#D1C985' : altStatus === 'in_progress' ? '#FFEB3B' : '#CABA32',
        border: 'none',
        color: '#222',
        overflow: 'hidden',
      };
    case 'maintenance' || 'changeover':
      return {
        background: 'rgba(191, 191, 4, 0.7)',
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid #E4D192',
        color: '#364252',
        overflow: 'hidden',
      };
    default:
      return {
        background: '#eee',
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid gray',
        color: 'black',
        overflow: 'hidden',
      };
  }
};

export const groupsTransformer = (data) => {
  const sortedData = data ? data.sort((a, b) => a.id - b.id) : [];
  if (sortedData && sortedData.length > 0) {
    for (let i = 0; i < sortedData.length; i++) {
      sortedData[i].title = sortedData[i].name;

      delete sortedData[i].location;
      delete sortedData[i].code;
      delete sortedData[i].name;
    }
    return sortedData;
  }
};

export const comparisonGroupsTransformer = (data) => {
  const sortedData = data ? [...data].sort((a, b) => a.id - b.id) : [];
  const result = [];
  for (let i = 0; i < sortedData.length; i++) {
    const originalItem = sortedData[i];

    // Create a copy of the original item and modify the title for _planned
    const plannedItem = { ...originalItem };
    plannedItem.title += ` (${i18n.t('page_content.microplanning.planned')})`;
    plannedItem.id += '_planned';

    // Create a copy of the original item and modify the title for _actual
    const actualItem = { ...originalItem };
    actualItem.title += ` (${i18n.t('page_content.microplanning.actual')})`;
    actualItem.id += '_actual';

    result.push(plannedItem, actualItem);
  }
  return result;
};

export const actualEventsTransformer = (data, language, isBOM = false) => {
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      data[i].event_type = data[i].reason ? 'maintenance' : data[i].order ? 'order' : null;
      data[i].group = data[i].line && data[i].line.id ? data[i].line.id : null;
      data[i].title = data[i].reason ? data[i].reason.name : data[i].order && data[i].order.external_id ? data[i].order.external_id : '';
      data[i].start_time = data[i].actual_start && data[i].actual_end ? moment(data[i].actual_start).valueOf() : data[i].planned_start ? moment(data[i].planned_start).valueOf() : '';
      data[i].end_time = data[i].actual_end && data[i].actual_start ? moment(data[i].actual_end).valueOf() : data[i].planned_end ? moment(data[i].planned_end).valueOf() : '';
      data[i].actualStart = data[i].actual_start ? moment(data[i].actual_start).valueOf() : '';
      data[i].actualEnd = data[i].actual_end ? moment(data[i].actual_end).valueOf() : '';
      data[i].plannedStart = data[i].planned_start ? moment(data[i].planned_start).valueOf() : '';
      data[i].plannedEnd = data[i].planned_end ? moment(data[i].planned_end).valueOf() : '';
      data[i].is_planned = !(data[i].actual_start && data[i].actual_end);
      data[i].canMove = true;
      data[i].canResize = false;
      data[i].canChangeGroup = false;
      data[i].norm = data[i].norm || 0;
      data[i].order_items_done = data[i].order && data[i].order.items_done ? numberSeparatorFormat(language, data[i].order.items_done, 0, 0) : 0;
      data[i].order_items_total = data[i].order && data[i].order.items_total ? numberSeparatorFormat(language, data[i].order.items_total, 0, 0) : 0;
      data[i].quantity_percentage = data[i].order && data[i].order.items_done && data[i].order.items_total ?
        numberSeparatorFormat(language, (numberSeparatorFormat(language, data[i].order.items_done, 0, 0) / numberSeparatorFormat(language, data[i].order.items_total, 0, 0)) * 100, 0, 0) : null;
      // data[i].itemProps = data[i].reason ? getStyleByStatus('maintenance') : data[i].status ? getStyleByStatus(data[i].status) : '';
      data[i].needs = data[i].needs && data[i].needs[0] && isBOM ? data[i].needs[0] : '';
      data[i].isBOM = isBOM;

      delete data[i].line;
      delete data[i].capacity;
      delete data[i].capacity_default;
      delete data[i].planned_start;
      delete data[i].planned_end;
      delete data[i].norm_value_type;
      delete data[i].actual_start;
      delete data[i].actual_end;
      // delete data[i].order;
      // delete data[i].reason;
      // delete data[i].norm;
      // delete data[i].status;
    }
    return data;
  }
};

export const comparisonEventsTransformer = (data, language) => {
  if (data && data.length > 0) {
    const newData = []; // Array to store new objects

    for (let i = 0; i < data.length; i++) {
      data[i].event_type = data[i].reason ? 'maintenance' : data[i].order ? 'order' : null;
      data[i].group = data[i].line && data[i].line.id ? `${data[i].line.id}_planned` : null;
      data[i].title = data[i].reason ? data[i].reason.name : data[i].order && data[i].order.external_id ? data[i].order.external_id : '';
      data[i].start_time = data[i].planned_start ? moment(data[i].planned_start).valueOf() : '';
      data[i].end_time = data[i].planned_end ? moment(data[i].planned_end).valueOf() : '';
      data[i].actualStart = data[i].actual_start ? moment(data[i].actual_start).valueOf() : '';
      data[i].actualEnd = data[i].actual_end ? moment(data[i].actual_end).valueOf() : '';
      data[i].plannedStart = data[i].planned_start ? moment(data[i].planned_start).valueOf() : '';
      data[i].plannedEnd = data[i].planned_end ? moment(data[i].planned_end).valueOf() : '';
      data[i].is_planned = true;
      data[i].canMove = true;
      data[i].canResize = false;
      data[i].canChangeGroup = false;
      data[i].norm = data[i].norm || 0;
      data[i].isBOM = false;
      data[i].order_items_done = data[i].order && data[i].order.items_done ? numberSeparatorFormat(language, data[i].order.items_done, 0, 0) : 0;
      data[i].order_items_total = data[i].order && data[i].order.items_total ? numberSeparatorFormat(language, data[i].order.items_total, 0, 0) : 0;
      data[i].quantity_percentage = data[i].order && data[i].order.items_done && data[i].order.items_total ?
        numberSeparatorFormat(language, (numberSeparatorFormat(language, data[i].order.items_done, 0, 0) / numberSeparatorFormat(language, data[i].order.items_total, 0, 0)) * 100, 0, 0) : null;

      if (data[i].actual_start && data[i].actual_end) {
        const newObject = {
          ...data[i],
          id: `${Math.random().toString().slice(2, 8)}${i}`,
          start_time: moment(data[i].actual_start).valueOf(),
          end_time: moment(data[i].actual_end).valueOf(),
          is_planned: false,
          group: `${data[i].line.id}_actual`,
        };

        newData.push(newObject); // Store new objects in the separate array
      }

      delete data[i].line;
      delete data[i].capacity;
      delete data[i].capacity_default;
      delete data[i].planned_start;
      delete data[i].planned_end;
      delete data[i].norm_value_type;
      delete data[i].actual_start;
      delete data[i].actual_end;
    }

    // Append new objects to the original data array
    data.push(...newData);

    return data;
  }

  return data;
};

export const defaultHeaderFormats = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY',
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'dddd, LL',
    mediumLong: 'dddd, LL',
    medium: 'dd D',
    short: 'D',
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
  second: {
    long: 'mm:ss',
    mediumLong: 'mm:ss',
    medium: 'mm:ss',
    short: 'ss',
  },
};
