export const isSetsEqual = (a, b) => a.size === b.size && [...a].every((value) => b.has(value));

export const generateAbsolutePath = (url) => `${process.env.NODE_ENV === 'development' ? '' : '/static'}${url}`;

export const isEmptyArray = (arr) => arr && arr.length === 0;

export const isProductionEnv = () => process.env.NODE_ENV === 'production';

export const getCustomDateString = (from, to) => {
  if ((from.hours() !== 0) || (to.hours() !== 0)) {
    return `${from.format('DD.MM.YYYY. HH:mm')} - ${to.format('DD.MM.YYYY. HH:mm')}`;
  }
  return `${from.format('DD.MM.YYYY.')} - ${to.format('DD.MM.YYYY.')}`;
};

export const shiftTimesValidation = (shiftTimes) => {
  const ordersToCheck = [1, 2, 3];
  return ordersToCheck.every((order) => shiftTimes.some((obj) => obj.order === order));
};
