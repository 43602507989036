import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Button, DateFilters, TableButtons, ContentLoader } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { styledIndividualGoalsStatus } from 'industry/helpers';
import LimitedTextarea from 'shared/LimitedTextarea/index';
import { getLocale } from 'shared/DatePicker/constants';
import { IconRemove, IconWarning } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import { colors } from 'shared/colors';
import '../../../styles.scss';

import api from 'helpers/api';
import {
  getWorker,
  addIndividualGoal,
  getIndividualGoals,
  editIndividualGoal,
  getAllIndividualScores,
  getIndividualGoalTemplates,
  getPaginatedIndividualGoals,
} from '../../../actions';

const IndividualGoalsRating = ({ t, selectedWorker, companyId, currentUser, locationId, userHaveFullAccess }) => {
  const [individualGoals, setIndividualGoals] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [additionalData, setAdditionalData] = useState({
    answerOptions: [],
    templateOptions: [],
    workers: [],
    isLoading: true,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    filters: 'start_date',
    start: moment().subtract(6, 'months').toISOString(),
    end: moment().toISOString(),
  });
  const [errorById, setErrorById] = useState([]);

  const fetchWorkersForUniqueUsers = (data) => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const uniqueUsers = [...new Set(data.map((item) => item.user))];

    const fetchPromises = uniqueUsers.map((user) => getWorker(locationId, companyId, `&user=${user}`));

    Promise.all(fetchPromises)
      .then((results) => {
        const workersMap = results
          .flatMap((res) => get(res, 'data.results', []))
          .map((worker) => ({
            id: worker.user.id,
            name: worker.name,
            last_name: worker.last_name,
          }));

        setAdditionalData((prevState) => ({
          ...prevState,
          workers: workersMap,
          isLoading: false,
        }));
      })
      .catch(() => {
        setAdditionalData((prevState) => ({
          ...prevState,
          workers: [],
          isLoading: false,
        }));
      });
  };

  const fetchIndividualGoals = () => {
    setIndividualGoals((prevState) => ({ ...prevState, isLoading: true }));

    let filters = `&worker=${selectedWorker.id}&order_by=-created_at&limit=15`;

    if (selectedFilters?.start && selectedFilters?.end && selectedFilters?.filters) {
      filters += `&${selectedFilters.filters}_after=${moment(selectedFilters.start).format('YYYY-MM-DD')}&${selectedFilters.filters}_before=${moment(selectedFilters.end).format('YYYY-MM-DD')}`;
    }

    getIndividualGoals(companyId, filters)
      .then((res) => {
        const data = get(res, 'data.results', []);

        fetchWorkersForUniqueUsers(data);

        setIndividualGoals({
          data,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', null),
          isLoading: false,
        });
      })
      .catch(() => {
        setIndividualGoals((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedIndividualGoals = (url) => {
    setIndividualGoals((prevState) => ({ ...prevState, isLoading: true }));

    getPaginatedIndividualGoals(url)
      .then((res) => {
        const data = get(res, 'data.results', []);

        fetchWorkersForUniqueUsers(data);

        setIndividualGoals({
          data,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', null),
          isLoading: false,
        });
      })
      .catch(() => {
        setIndividualGoals((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchAllIndividualScores = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const filters = '&order_by=-score&limit=9999';

    getAllIndividualScores(companyId, filters)
      .then((res) => {
        setAdditionalData((prevState) => ({
          ...prevState,
          answerOptions: get(res, 'data.results', []),
          isLoading: false,
        }));
      })
      .catch(() => {
        setAdditionalData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchIndividualGoalTemplates = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const apiFilters = `&limit=9999&order_by=-name&department=${selectedWorker?.location_department?.id}`;

    getIndividualGoalTemplates(companyId, apiFilters)
      .then((res) => {
        setAdditionalData((prevState) => ({
          ...prevState,
          templateOptions: get(res, 'data.results', []),
          isLoading: false,
        }));
      })
      .catch(() => {
        setAdditionalData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchIndividualGoals();
    fetchIndividualGoalTemplates();
    fetchAllIndividualScores();
  }, []);

  useEffect(() => {
    fetchIndividualGoals();
  }, [selectedFilters]);

  const handleSelectedDateFilter = (filters, start, end) => {
    setSelectedFilters({ filters, start, end });
  };

  const handleAddNewGoal = () => {
    const tempData = [...individualGoals?.data];
    if (!tempData.some((obj) => 'fakeId' in obj)) {
      tempData.unshift({ fakeId: Math.random(), name: '', selectedName: null, kpi: '', note: '', start_date: null, deadline: null, real_end_date: null, score: null });
      setIndividualGoals((prevState) => ({
        ...prevState,
        data: tempData,
      }));
    }
  };

  const handleRemoveNewGoal = (goal, index) => {
    const tempData = [...individualGoals?.data];
    if (!tempData) return;
    const updatedTempData = [...tempData.slice(0, index), ...tempData.slice(index + 1)];
    setErrorById((prevState) => ({ ...prevState, [goal?.id || goal?.fakeId]: null }));
    setIndividualGoals((prevState) => ({
      ...prevState,
      data: updatedTempData,
    }));
  };

  const isValid = (value) => value !== undefined && value !== null && value !== '' && value !== false && value !== 0;

  const validatePayloadDetails = (goals) => {
    return [
      !isValid(goals?.selectedName ? goals.selectedName?.name : goals?.name) && t('page_content.human_resources.rating.individual_goals_tab.error_invalid_name'),
      !isValid(goals?.kpi) && t('page_content.human_resources.rating.individual_goals_tab.error_invalid_KPI'),
      !isValid(goals?.start_date) && t('page_content.human_resources.rating.individual_goals_tab.error_invalid_start_date'),
      !isValid(goals?.deadline) && t('page_content.human_resources.rating.individual_goals_tab.error_invalid_end_date'),
    ].filter(Boolean);
  };

  const handleSaveNewGoal = (goal) => {
    const validationResults = validatePayloadDetails(goal);
    const isPayloadValid = Object.values(validationResults).every((result) => result === null);

    if (isPayloadValid) {
      setIndividualGoals((prevState) => ({ ...prevState, isLoading: true }));
      const payload = {
        name: goal?.selectedName ? goal.selectedName?.name : goal?.name,
        kpi: goal?.kpi,
        note: goal?.note,
        start_date: goal?.start_date && moment(goal.start_date).format('YYYY-MM-DD'),
        deadline: goal?.deadline && moment(goal.deadline).format('YYYY-MM-DD'),
        real_end_date: null,
        score: null,
        user: currentUser?.id,
        worker: selectedWorker?.id,
      };

      setErrorById((prevState) => ({ ...prevState, [goal?.id || goal?.fakeId]: null }));

      addIndividualGoal(companyId, payload)
        .then(() => fetchIndividualGoals())
        .catch(() => setIndividualGoals((prevState) => ({ ...prevState, isLoading: false })));
    } else setErrorById((prevState) => ({ ...prevState, [goal?.id || goal?.fakeId]: validationResults[0] }));
  };

  const handleSubmitGoal = (goal) => {
    const isPayloadValid = !!goal?.score;

    if (isPayloadValid) {
      setIndividualGoals((prevState) => ({ ...prevState, isLoading: true }));
      const payload = {
        name: goal?.selectedName ? goal.selectedName?.name : goal?.name,
        kpi: goal?.kpi,
        note: goal?.note,
        start_date: goal?.start_date && moment(goal.start_date).format('YYYY-MM-DD'),
        deadline: goal?.deadline && moment(goal.deadline).format('YYYY-MM-DD'),
        real_end_date: moment().format('YYYY-MM-DD'),
        score: goal?.score?.id,
        user: currentUser?.id,
        worker: selectedWorker?.id,
      };

      setErrorById((prevState) => ({ ...prevState, [goal?.id || goal?.fakeId]: null }));

      editIndividualGoal(companyId, goal?.id, payload)
        .then(() => fetchIndividualGoals())
        .catch(() => setIndividualGoals((prevState) => ({ ...prevState, isLoading: false })));
    } else setErrorById((prevState) => ({ ...prevState, [goal?.id || goal?.fakeId]: t('page_content.human_resources.rating.individual_goals_tab.error_invalid_score') }));
  };

  const handleAnswerChange = (index, value) => {
    const tempData = [...individualGoals?.data];
    const tempItem = { ...tempData[index] };
    tempItem.score = value;
    tempData[index] = tempItem;
    setIndividualGoals((prevState) => ({
      ...prevState,
      data: tempData,
    }));
  };

  const handleChange = (index, key, value) => {
    const tempData = [...individualGoals?.data];
    if (index < 0 || index >= tempData.length) return;
    const tempItem = { ...tempData[index] };
    tempItem[key] = value;
    tempData[index] = tempItem;
    setIndividualGoals((prevState) => ({
      ...prevState,
      data: tempData,
    }));
  };

  const renderGoalRow = (goal, index) => {
    const submitter = additionalData?.workers?.find((worker) => worker.id === goal?.user);
    return (
    <div key={goal.id} className="modal_row">
      <div className="left_text">
        <div className="left_text__half">
          <label>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_name')}: {goal?.name || '-'}</label>
          <label>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_kpi')}: {goal?.kpi || '-'}</label>
        </div>
        <div className="left_text__half" style={{ alignItems: 'center' }}>
          <label>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_start')}: {goal?.start_date ?
            moment(goal?.start_date, 'YYYY-MM-DD').format(defaultDateFormat) : '-'}</label>
          <label>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_end')}: {goal?.deadline ?
            moment(goal?.deadline, 'YYYY-MM-DD').format(defaultDateFormat) : '-'}</label>
        </div>
        <div className="left_text__half" style={{ alignItems: 'center' }}>
          <label style={styledIndividualGoalsStatus(goal?.real_end_date ? 'completed' : '')}>
            {goal?.real_end_date ?
              `${t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_real_end_positive')}: ${moment(goal.real_end_date, 'YYYY-MM-DD').format(defaultDateFormat)}`
              :
              t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_real_end')}
          </label>
        </div>
      </div>
      <div className="right_input_individual_goals">
        <div className="right_input_individual_goals__select">
          <Select
            options={additionalData?.answerOptions}
            getOptionLabel={(option) => (userHaveFullAccess
              ? `${option.name} - ${option.score} ${t('page_content.human_resources.rating.points')}`
              : `${option.name}`)}
            getOptionValue={(option) => option.id}
            isSearchable
            isClearable
            placeholder={t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_select_answer')}
            value={additionalData?.answerOptions?.find((option) => option?.id === goal?.score?.id) || null}
            onChange={(opt) => handleAnswerChange(index, opt || null)}
            isDisabled={goal?.real_end_date}
            styles={selectModalStyles}
          />
        </div>
        {
          !goal?.real_end_date &&
            <div style={{ width: '100%' }}>
              <span>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_note')}:</span>
              <LimitedTextarea autoResize value={goal?.note || ''} onChange={(value) => handleChange(index, 'note', value)} />
            </div>
        }
        {
          goal?.real_end_date &&
            <>
              <span>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_note')}: {goal?.note || '-'}</span>
              <span>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_evaluator')}: {submitter?.name && submitter?.last_name ? `${submitter.name} ${submitter.last_name}` : '-'}</span>
            </>
        }
        {
          !goal?.real_end_date &&
            <div className="save_button">
              {errorById[goal?.id || goal?.fakeId] && <span>{errorById[goal?.id || goal?.fakeId]}</span>}
              <Button type="success" onClick={() => handleSubmitGoal(goal, index)}>{t('page_content.human_resources.rating.individual_goals_tab.button_submit_goal')}</Button>
            </div>
        }
      </div>
    </div>
    );
  };

  const renderNewGoalRow = (goal, index) => (
    <div key={goal.fakeId} className="modal_row__edit">
      <div className="left_text_edit">
        <div className="left_text_edit__half">
          <div className="left_text_edit__half__inputs">
            <label>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_name')}: *</label>
            <div className="left_text_edit__half__inputs__double">
              <input
                className="input_field"
                disabled={goal?.selectedName?.id}
                value={goal?.selectedName ? goal?.selectedName?.name : goal?.name || ''}
                placeholder={t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_name')}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
                type="text"
              />
              <label>{t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_or')}</label>
              <div style={{ width: '100%' }}>
                <Select
                  options={additionalData?.templateOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  isDisabled={goal?.name !== ''}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_select')}
                  onChange={(value) => handleChange(index, 'selectedName', value)}
                  value={(additionalData?.templateOptions?.find((a) => a.id === goal?.selectedName?.id)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
          <div className="left_text_edit__half__inputs">
            <label>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_kpi')}: *</label>
            <input
              className="input_field"
              value={goal?.kpi || ''}
              placeholder={t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_kpi')}
              onChange={(e) => handleChange(index, 'kpi', e.target.value)}
              type="text"
            />
          </div>
        </div>
        <div className="left_text_edit__half">
          <div className="left_text_edit__half__date_picker">
            <span>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_start')}: *</span>
            <DatePicker
              className="date_picker"
              placeholderText={t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_date_start')}
              dateFormat="dd.MM.yyyy"
              selected={goal?.start_date ? moment(goal.start_date).toDate() : null}
              onChange={(date) => handleChange(index, 'start_date', moment(date))}
              locale={getLocale(t)}
            />
          </div>
          <div className="left_text_edit__half__date_picker">
            <span>{t('page_content.human_resources.rating.individual_goals_tab.field_placeholder_goal_end')}: *</span>
            <DatePicker
              className="date_picker"
              placeholderText={t('page_content.human_resources.rating.individual_goals_tab.input_placeholder_goal_date_end')}
              dateFormat="dd.MM.yyyy"
              selected={goal?.deadline ? moment(goal.deadline).toDate() : null}
              onChange={(date) => handleChange(index, 'deadline', moment(date))}
              locale={getLocale(t)}
            />
          </div>
        </div>
        <div className="left_text_edit__half_full_height">
          <div className="right_input_individual_goals_edit__limited_text">
            <div className="right_input_individual_goals_edit__limited_text__delete">
              <Button type="delete" className="remove_btn" onClick={() => handleRemoveNewGoal(goal, index)}><IconRemove width="14px" height="14px" /></Button>
            </div>
          </div>
          {
            !goal?.real_end_date &&
              <div className="save_button">
                {errorById[goal?.id || goal?.fakeId] && <span>{errorById[goal?.id || goal?.fakeId]}</span>}
                <Button type="success" onClick={() => handleSaveNewGoal(goal, index)}>{t('page_content.human_resources.rating.individual_goals_tab.button_create_new_goal')}</Button>
              </div>
          }
        </div>
      </div>
    </div>
  );

  const exportToExcel = () => {
    let filters = `&worker=${selectedWorker.id}&order_by=-created_at&limit=15`;

    if (selectedFilters?.start && selectedFilters?.end && selectedFilters?.filters) {
      filters += `&${selectedFilters.filters}_after=${moment(selectedFilters.start).format('YYYY-MM-DD')}&${selectedFilters.filters}_before=${moment(selectedFilters.end).format('YYYY-MM-DD')}`;
    }

    api.get(`/api/v1/scores/individual_goals/?company=${companyId}${filters}&format=xlsx&limit=${individualGoals?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Individual_Goals.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const individualGoalsFilterOptions = [
    { id: 'start_date', label: t('page_content.human_resources.rating.individual_goals_tab.filter_option_start_date'), showTime: false },
    { id: 'deadline', label: t('page_content.human_resources.rating.individual_goals_tab.filter_option_end_date'), showTime: false },
    { id: 'real_end_date', label: t('page_content.human_resources.rating.individual_goals_tab.filter_option_real_end_date'), showTime: false },
  ];

  return (
    <div className="ratings_screen_individual_goals">
      <div className="ratings_screen_individual_goals__header_filters">
        <div>
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={individualGoalsFilterOptions}
            defaultDate={selectedFilters}
          />
        </div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Button type="add" onClick={handleAddNewGoal}>{t('page_content.human_resources.rating.individual_goals_tab.button_assign_new_goal')}</Button>
          <Button type="export" onClick={() => exportToExcel()}>{t('page_content.human_resources.rating.individual_goals_tab.button_export_to_excel')}</Button>
        </div>
      </div>

      {
        !additionalData.isLoading || !individualGoals.isLoading ?
          <div className="custom_inputs_view">
          {
            !currentUser?.worker_id ?
              <span className="ratings_screen_individual_goals__warning">
                <IconWarning color={colors.red} height="18px" width="18px" />
                <p>{t('page_content.human_resources.rating.no_rating')}</p>
              </span>
              :
              individualGoals?.data?.length > 0 ? individualGoals?.data?.map((goal, index) => (
                goal?.fakeId ? renderNewGoalRow(goal, index) : renderGoalRow(goal, index)
              ))
                :
                <span className="ratings_screen_individual_goals__warning">
                  <IconWarning color={colors.red} height="18px" width="18px" />
                  <p>{t('page_content.human_resources.rating.individual_goals_tab.error_no_individual_goals')}</p>
                </span>
          }
          </div> : <ContentLoader />
      }
      <div style={{ float: 'right' }}>
        <TableButtons
          previous={individualGoals?.previous}
          fetchFunction={fetchPaginatedIndividualGoals}
          count={individualGoals?.count}
          next={individualGoals?.next}
        />
      </div>
    </div>
  );
};

IndividualGoalsRating.propTypes = {
  t: PropTypes.func,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(IndividualGoalsRating));
