import api from 'helpers/api';
import { Notification } from 'shared';

export const getNotifications = async (companyId, userId) => {
  try {
    const res = await api.get(`/api/v1/notifications/app_notifications/?company=${companyId}&user=${userId}&is_read=false&order_by=-created_at`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching notifications', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateNotifications = async (data) => {
  try {
    const res = await api
      .post('/api/v1/notifications/set_is_read/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};
