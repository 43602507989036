import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Table, Button } from 'shared';
import { IconSearch } from 'shared/Icons';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { getWorkersList, getAllDepartments, getPartners } from '../../actions';

import RatingScreen from './components/RatingScreen';

const Ratings = ({ t, companyId, locationId, customDepartment, userHaveFullAccess }) => {
  const debounceTimeoutRef = useRef(null);

  const [selectedWorker, setSelectedWorker] = useState(null);

  const [tableData, setTableDate] = useState({
    workers: [],
    count: null,
    next: null,
    previous: null,
    isLoading: false,
  });

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    department: null,
    company: null,
  });

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const fetchWorkers = async () => {
    setTableDate((prevState) => ({ ...prevState, isLoading: true }));
    let urlFilters = '';
    if (query) {
      urlFilters += `&first_or_last_name=${query}`;
    }

    if (filters?.department) {
      const departmentFilterValue = filters?.department;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.company) {
      const companiesFilterValue = filters?.company;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    const resp = await getWorkersList(locationId, companyId, urlFilters);
    setTableDate({
      workers: get(resp, 'data.results', []),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      count: get(resp, 'data.count'),
      isLoading: false,
    });
  };

  const fetchDepartments = async () => {
    const res = await getAllDepartments(locationId);
    setDepartmentOptions(get(res, 'data.results', []));
  };

  const fetchCompanies = async () => {
    const res = await getPartners(companyId);
    setCompaniesOptions(get(res, 'data.results', []));
  };

  useEffect(() => {
    fetchDepartments();
    fetchCompanies();
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [filters]);

  const handleRateButton = (worker) => {
    setSelectedWorker(worker?.original);
  };

  const handleBackButton = () => {
    setSelectedWorker(null);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchWorkers();
      }, 300);
    }
  }, [query]);

  const clearAllFilters = () => {
    setFilters({
      department: null,
      company: null,
    });
    setQuery('');
  };

  return (
    <div className="ratings_container">
      {
        selectedWorker ?
          <RatingScreen
            t={t}
            selectedWorker={selectedWorker}
            handleBackButton={handleBackButton}
            userHaveFullAccess={userHaveFullAccess}
          /> :
          <div className="ratings_container__table">
          <div className="ratings_container__table__filters">
          <div className="ratings_container__table__filters__input">
            <input
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t('page_content.human_resources.rating.search_by_first_or_last_name')}
              value={query}
            />
            {query &&
              <button
                onClick={() => {
                  setQuery('');
                }}
              >
                &times;
              </button>}
            <div className="ratings_container__table__filters__input__icon">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Select
            className="select-style"
            options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.rating.filter_by_department')}
            isClearable
            onChange={(e) => handleFilterChange('department', e)}
            value={departmentOptions.find((a) => a.id === filters?.department?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={companiesOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.rating.filter_by_company')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('company', e)}
            value={companiesOptions.find((a) => a.id === filters?.company?.id) || ''}
            styles={selectModalStyles}
          />
           <div className="clear_button">
              <Button
                type="plain"
                onClick={clearAllFilters}
              >
                {t('page_content.human_resources.rating.clear_all')}
              </Button>
            </div>
          </div>
            <Table
              style={{ userSelect: 'text' }}
              columns={
                [
                  {
                    Header: () => <span>{t('page_content.human_resources.rating.table_column_first_name')}</span>,
                    accessor: 'name',
                    Cell: (row) => (row && row.value ? row.value : '-'),
                  },
                  {
                    Header: () => <span>{t('page_content.human_resources.rating.table_column_last_name')}</span>,
                    accessor: 'last_name',
                    Cell: (row) => (row && row.value ? row.value : '-'),
                  },
                  {
                    Header: () => <span>{t('page_content.human_resources.rating.department')}</span>,
                    accessor: `${customDepartment}.name` || 'department',
                    Cell: (row) => (row.value ? row.value : '-'),
                  },
                  {
                    Header: () => <span>{t('page_content.human_resources.rating.working_place')}</span>,
                    accessor: 'working_place.name',
                    Cell: (row) => (row.value ? row.value : '-'),
                  },
                  {
                    Header: () => <span>{t('page_content.human_resources.rating.company')}</span>,
                    accessor: 'partner',
                    Cell: (row) => (row.value ? row.value.name : '-'),
                  },
                  {
                    Header: () => <span>{t('page_content.human_resources.rating.table_column_button_rate_worker')}</span>,
                    Cell: (row) => <Button type="dark-blue" onClick={() => handleRateButton(row)}>{t('page_content.human_resources.rating.table_column_button_rate_worker')}</Button>,
                  },
                ]
              }
              data={tableData?.workers}
              loading={tableData.isLoading}
              noDataText=" "
              showPagination={false}
              minRows={0}
              defaultPageSize={30}
              selectedRow={null}
            />
          </div>
      }
    </div>
  );
};

Ratings.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  customDepartment: PropTypes.string,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    customDepartment: get(state, 'app.companyConfig.config.custom_department', null),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(Ratings)));
