import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Table, TableButtons, Button, Modal, DateFilters } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { selectStyles } from 'styles/modules/reactSelect';
import { IconSearch } from 'shared/Icons';
import './styles.scss';

import api from 'helpers/api';
import {
  getDocuments,
  getAllWorkers,
  getEmployments,
  getPaginatedEmployments,
  getTerminationReasons,
} from './actions';

import AddLayoffModal from './AddLayoffModal';

const Layoffs = ({ t, companyId, locationId, userHaveFullAccess = false }) => {
  const debounceTimeoutRef = useRef(null);
  const [tableData, setTableData] = useState({
    isLoading: true,
    next: null,
    previous: null,
    count: null,
    layoffs: [],
  });
  const filterOptions = [
    { name: t('page_content.human_resources.layoffs.table_column_first_name'), id: 'name' },
    { name: t('page_content.human_resources.layoffs.table_column_last_name'), id: 'last_name' }];
  const [query, setQuery] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({ query: filterOptions[0] });
  const [dateFilter, setDateFilter] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [selectedAscDesc, setSelectedAscDesc] = useState('desc');
  const [selectedSort, setSelectedSort] = useState('end_working');
  const [editData, setEditData] = useState(null);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [layoffDocuments, setLayoffDocuments] = useState([]);
  const [clearDateFilters, setClearDateFilters] = useState(false);

  const fetchDocuments = async (layoffs) => {
    const resp = await getDocuments(companyId);
    const documents = get(resp, 'data.results', []);
    const updatedData = layoffs.map((layoff) => {
      const tempData = layoff;
      tempData.files = documents.filter((d) => d?.employment === layoff?.id);
      return tempData;
    });
    setTableData((prevState) => ({
      ...prevState,
      layoffs: updatedData,
      isLoading: false,
    }));
  };

  const fetchReasons = async () => {
    const res = await getTerminationReasons(companyId);
    const newReasons = [{ id: 'all', name: t('page_content.human_resources.layoffs.reason_filter_all') }];
    newReasons.push(...get(res, 'data.results', []));
    setReasons(newReasons);
  };

  const fetchLayoffs = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    let filters = '';
    if (selectedFilter?.query && query) {
      filters += `&${selectedFilter.query.id}=${query}`;
    }
    if (selectedFilter?.reason && selectedFilter?.reason?.id !== 'all') {
      filters += `&termination_reason=${selectedFilter?.reason?.id}`;
    }
    if (dateFilter?.start && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_after=${moment(dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (dateFilter?.start && dateFilter?.end && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_before=${moment(dateFilter.end).format('YYYY-MM-DD')}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    const res = await getEmployments(companyId, filters);
    const layoffs = get(res, 'data.results', []);
    setTableData((prevState) => ({
      ...prevState,
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
    }));
    fetchDocuments(layoffs);
  };

  const fetchPaginatedLayoffs = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getPaginatedEmployments(url);
    const layoffs = get(res, 'data.results', []);
    setTableData({
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
    });
    fetchDocuments(layoffs);
  };

  const fetchWorkers = async () => {
    const res = await getAllWorkers(companyId, locationId);
    setWorkers(get(res, 'data.results', []));
  };

  useEffect(() => {
    fetchWorkers();
    fetchReasons();
  }, []);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        if (selectedFilter?.query) {
          fetchLayoffs();
        }
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    if ((selectedFilter?.date && dateFilter)) {
      fetchLayoffs();
    }
  }, [selectedFilter, dateFilter]);

  useEffect(() => {
    fetchLayoffs();
  }, [selectedAscDesc, selectedSort, selectedFilter]);

  const handleSorting = (sortData) => {
    setSelectedSort(sortData.id);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const clearAllFilters = () => {
    setSelectedFilter({ query: filterOptions[0] });
    setDateFilter(null);
    setQuery('');
    setClearDateFilters((prevState) => !prevState);
  };

  const handleChangeSelect = (key, e) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      [key]: e,
    }));
  };

  const handleAddLayoff = async () => {
    setShowModal(false);
    fetchLayoffs();
  };

  const handleRowClick = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  const handleDownloadDocument = (row) => {
    const documentUrl = row;
    const link = document.createElement('a');
    link.href = documentUrl;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenDocuments = (e, row) => {
    e.stopPropagation();
    setShowDocumentsModal(true);
    setLayoffDocuments(row);
  };

  const handleSelectedDateFilter = (filter, start, end) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      date: filter.id,
    }));
    setDateFilter({
      start, end,
    });
  };

  const exportToExcel = () => {
    let filters = '';
    if (selectedFilter?.query && query) {
      filters += `&${selectedFilter.query.id}=${query}`;
    }
    if (selectedFilter?.reason && selectedFilter?.reason?.id !== 'all') {
      filters += `&termination_reason=${selectedFilter?.reason?.id}`;
    }
    if (dateFilter?.start && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_after=${moment(dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (dateFilter?.start && dateFilter?.end && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_before=${moment(dateFilter.end).format('YYYY-MM-DD')}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    api.get(`/api/v1/workforce/employments/?company=${companyId}${filters}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Layoffs.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_first_name')}</span>,
      accessor: 'worker',
      Cell: (row) => (row && row.value ? row.value.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_last_name')}</span>,
      accessor: 'worker.last_name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_working_start')}</span>,
      accessor: 'start_working',
      Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_working_end')}</span>,
      accessor: 'end_working',
      Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_reason')}</span>,
      accessor: 'termination_reason',
      Cell: (row) => (row && row.value ? row.value.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_document')}</span>,
      accessor: 'files',
      width: 140,
      sortable: false,
      Cell: (row) => (row?.value?.length ? <Button onClick={(e) => handleOpenDocuments(e, row.value)}>{t('page_content.human_resources.layoffs.button_open')}</Button> : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_comment')}</span>,
      accessor: 'note',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
  ];

  const tableColumnsDocuments = [
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.table_column_first_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.layoffs.button_download')}</span>,
      accessor: 'file',
      width: 140,
      sortable: false,
      Cell: (row) => (row && row.value ? <Button
        onClick={() => handleDownloadDocument(row.value)}
      >{t('page_content.human_resources.layoffs.button_download')}</Button> : '-'),
    },
  ];

  return (
    <div className="layoffs_container">
      <div className="filters_container">
         <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.orders.search_for_placeholder')} value={query || ''} />
              {query && <button
                onClick={() => setQuery('')}
              >&times;</button>}
            </div>
          <Select
            className="select-style"
            options={filterOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.layoffs.filter_by')}
            onChange={(e) => handleChangeSelect('query', e)}
            value={selectedFilter?.query || ''}
            styles={selectStyles}
          />
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
            </div>
          </div>
          </div>
          <div className="filters">
          <Select
            className="select-style"
            options={reasons}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.layoffs.filter_by_reason')}
            onChange={(e) => handleChangeSelect('reason', e)}
            value={selectedFilter?.reason || ''}
            styles={selectStyles}
          />
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={[
              { label: t('page_content.human_resources.layoffs.table_column_working_end'), id: 'end_working', showTime: false },
              { label: t('page_content.human_resources.layoffs.table_column_working_start'), id: 'start_working', showTime: false }]}
            clearFilters={clearDateFilters}
          />
          <div className="clear_button">
              <Button
                type="plain"
                onClick={clearAllFilters}
              >
                {t('page_content.orders.clear_all_button')}
              </Button>
            </div>
          <div className="new_layoff_button">
              <Button
                disabled={!userHaveFullAccess}
                type="add"
                onClick={() => setShowModal(true)}
              >
                {t('page_content.human_resources.layoffs.button_new_layoff')}
              </Button>
            <Button type="export" onClick={() => exportToExcel()}>{t('page_content.human_resources.rating.individual_goals_tab.button_export_to_excel')}</Button>
            </div>
        </div>
        </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={tableColumns}
        data={tableData.layoffs}
        loading={tableData.isLoading}
        noDataText=" "
        showPagination={false}
        minRows={0}
        defaultPageSize={30}
        selectedRow={null}
        handleClick={(row) => (userHaveFullAccess ? handleRowClick(row.original) : null)}
        defaultSorted={[{ id: 'end_working', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div style={{ float: 'right' }}>
          <TableButtons next={tableData.next} previous={tableData.previous} fetchFunction={fetchPaginatedLayoffs} count={tableData.count} />
        </div>
        <AddLayoffModal
          companyId={companyId}
          showModal={showModal}
          handleClose={handleCloseModal}
          handleAddLayoff={handleAddLayoff}
          workers={workers}
          reasons={reasons}
          fetchReasons={fetchReasons}
          initialData={editData}
        />
        <Modal
          title={t('page_content.human_resources.layoffs.modal_title_documents')}
          isOpen={showDocumentsModal}
          handleClose={() => setShowDocumentsModal(false)}
        >
          <div>
          <Table
            style={{ userSelect: 'text' }}
            columns={tableColumnsDocuments}
            data={layoffDocuments}
            noDataText=" "
            showPagination={false}
            minRows={0}
            defaultPageSize={30}
            selectedRow={null}
            sortable={false}
          />
          </div>
        </Modal>
    </div>
  );
};

Layoffs.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default (withRouter(withTranslation()(Layoffs)));
