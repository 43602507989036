import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Button, ContentLoader } from 'shared';
import { IconBell, IconWarning } from 'shared/Icons';
import { getModuleIcon } from './helpers';
// import { subscribeUser } from '../service-worker';
import { getNotifications, updateNotifications } from '../../actions';
import './styles.scss';

const notificationMenuDomClass = 'notification-menu';

const Notifications = ({
  t,
  isPushNotificationSupported,
  isPushNotificationPermissionGranted,
  handleEnableNotifications,
  isServiceWorkerRegistered,
  companyId,
  currentUser,
  pushNotifications,
  notificationsCenter,
  locationId,
}) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState({
    selectedNotificationIds: [],
    selectedNotificationPool: [],
  });

  const fetchNotificationsCenterList = async () => {
    setIsLoading(true);

    getNotifications(companyId, currentUser?.id)
      .then((res) => {
        let notificationListResults = get(res, 'data.results', []);
        notificationListResults = notificationListResults.filter((not) => not?.action_code.includes('finished'));
        setNotificationList(notificationListResults);
        setIsLoading(false);
      })
      .catch(() => {
        setNotificationList([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let intervalId = null;
    if (notificationsCenter) {
      fetchNotificationsCenterList();
      intervalId = setInterval(fetchNotificationsCenterList, 300000);
    }
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (notificationsCenter && showNotifications) fetchNotificationsCenterList();
  }, [showNotifications]);

  const handleClickOutside = (e) => {
    if (e.target.closest(`.${notificationMenuDomClass}`) === null) {
      setShowNotifications(false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    }
  };

  const handleOpen = () => {
    setShowNotifications((prevState) => !prevState);
    if (!showNotifications) {
      document.addEventListener('mousedown', handleClickOutside, false);
    } else {
      document.removeEventListener('mousedown', handleClickOutside, false);
    }
  };

  const selectNotification = (e, object) => {
    const { selectedNotificationIds, selectedNotificationPool } = selectedNotifications;

    let updatedIds = [...selectedNotificationIds];
    let updatedBookings = [...selectedNotificationPool];
    const objectId = object.id;

    if (e.target.checked === true) {
      if (!updatedIds.includes(objectId)) {
        updatedIds.push(objectId);
        updatedBookings.push(object);
      }
    } else {
      updatedIds = updatedIds.filter((id) => id !== objectId);
      updatedBookings = updatedBookings.filter(
        (booking) => booking.id !== objectId,
      );
    }

    setSelectedNotifications({
      selectedNotificationIds: updatedIds,
      selectedNotificationPool: updatedBookings,
    });
  };

  const markAllAsSeen = () => {
    const { selectedNotificationIds } = selectedNotifications;
    const payload = {
      company: companyId,
      ids: selectedNotificationIds.join(','),
    };

    updateNotifications(payload).then(fetchNotificationsCenterList);

    setSelectedNotifications({
      selectedNotificationIds: [],
      selectedNotificationPool: [],
    });
    setSelectAllChecked(false);
  };

  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);

    const updatedIds = !selectAllChecked
      ? notificationList
        .filter((notification) => !notification.is_read)
        .map((notification) => notification.id)
      : [];

    setSelectedNotifications({
      selectedNotificationIds: updatedIds,
      selectedNotificationPool: [],
    });
  };

  const handleRedirect = (object) => {
    const baseUrl = window.location.origin;
    let redirectLocation = '';

    switch (object?.app_model) {
      case 'surveys.workersurvey':
        redirectLocation = `${baseUrl}/${companyId}/industry/location/${locationId}/human-resources`;
        break;
      default:
        redirectLocation = window.location.href;
    }

    window.location.href = redirectLocation;
  };

  const showNotificationsFunc = (list) => {
    return list
      .map((obj) => {
        return (
          <div
            key={obj.id}
            className={`notification_card${obj.is_read ? '-true' : ''}`}
          >
            <input
              className="checkbox_input"
              type="checkbox"
              value={get(obj, 'is_read', false)}
              disabled={get(obj, 'is_read', false)}
              onChange={(e) => selectNotification(e, obj)}
              checked={
                obj.is_read
                  ? false
                  : selectedNotifications?.selectedNotificationIds?.includes(
                    obj.id,
                  )
              }
            />
            <div
              className={`notification_card${obj.is_read ? '-true' : ''}`}
              onClick={() => handleRedirect(obj)}
            >
            <div className="notification_card-picture">
              {getModuleIcon(obj.app_model)}
            </div>
            <div className="notification_card_info">
              <div className="notification_card_info-title">
                {obj.title ?? 'No title'}
              </div>
              <div className="notification_card_info-description">
                {obj.description ?? 'No description'}
              </div>
            </div>
            </div>
          </div>
        );
      });
  };

  const showWarnings = (message) => {
    return (
      <div className="notification_card">
        <div className="notification_card-picture">
          <IconWarning color="red" height="16px" width="16px" />
        </div>
        <div className="notification_card_info">
          <div className="notification_card_info-title">Error</div>
          <div className="notification_card_info-description">
            <span>{message}</span>
          </div>
        </div>
      </div>
    );
  };

  const isWarning =
    (!isPushNotificationSupported ||
    !isPushNotificationPermissionGranted ||
    !isServiceWorkerRegistered) && pushNotifications;

  const countWarnings = [
    !isPushNotificationSupported,
    !isPushNotificationPermissionGranted,
    !isServiceWorkerRegistered,
  ].filter((warning) => warning).length;

  const { selectedNotificationIds } = selectedNotifications;
  return (
    <div className={`${notificationMenuDomClass} header-link`}>
      <div className="link" onClick={handleOpen}>
        <IconBell height="24px" width="24px" />
        {isWarning && pushNotifications ? (
          <div className="number-indicator">{countWarnings}</div>
        ) : notificationList?.length > 0 ? (
          <div className="number-indicator">{notificationList?.length}</div>
        ) : null}
      </div>

      {showNotifications && (
        <div className="notification_content">
          <div
            className="notification_header"
            style={!isWarning ? null : { paddingBottom: '5px' }}
          >
            <div className="notification_content-title">
              {t('top_header.notifications.title')}
              {isPushNotificationSupported && pushNotifications &&
                (!isPushNotificationPermissionGranted ||
                  !isServiceWorkerRegistered) && (
                  <Button type="add" onClick={handleEnableNotifications}>
                    {t('top_header.notifications.button_enable_notifications')}
                  </Button>
              )}
            </div>
            {(!isWarning && notificationsCenter) && (
              <div className="mark_seen">
                <div className="checkbox_numbering">
                  <input
                    className="checkbox_input"
                    type="checkbox"
                    value={false}
                    disabled={false}
                    checked={selectAllChecked}
                    onChange={handleSelectAll}
                  />
                  {selectedNotificationIds?.length > 0 && <span>({selectedNotificationIds?.length})</span>}
                </div>
                <Button
                  type="add"
                  onClick={markAllAsSeen}
                  disabled={!selectedNotificationIds?.length > 0}
                >
                  {t('top_header.notifications.button_mark_as_seen')}
                </Button>
              </div>
            )}
          </div>

          {isLoading ? <div><ContentLoader /></div> : (
            isWarning && pushNotifications ? (
              <>
                {!isPushNotificationSupported &&
                  showWarnings(t('top_header.notifications.warning_no_api_support'))}
                {!isPushNotificationPermissionGranted &&
                  showWarnings(t('top_header.notifications.warning_no_permission'))}
                {!isServiceWorkerRegistered &&
                  showWarnings(t('top_header.notifications.warning_not_registered'))}
              </>
            ) : notificationList?.length > 0 ? (
              showNotificationsFunc(notificationList)
            ) : (
              <div className="no_notification_text">
                {t('top_header.notifications.warning_no_notifications')}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

Notifications.propTypes = {
  t: PropTypes.func.isRequired,
  isPushNotificationSupported: PropTypes.bool.isRequired,
  isPushNotificationPermissionGranted: PropTypes.bool.isRequired,
  handleEnableNotifications: PropTypes.func,
  isServiceWorkerRegistered: PropTypes.bool,
  notificationsCenter: PropTypes.bool.isRequired,
  pushNotifications: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  notificationsCenter: get(state, 'app.location.config.notifications_center', false),
  pushNotifications: get(state, 'app.location.config.push_notifications', false),
});

export default connect(mapStateToProps, null)(withRouter(withTranslation()(Notifications)));
