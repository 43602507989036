import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { IconInfo, IconRemove, IconShowPassword, IconHidePassword } from 'shared/Icons';
import userGreen from 'shared/Icons/workforce/user-green.png';
import { Modal, Button, ContentLoader } from 'shared';
import Select from 'react-select';
import { get } from 'lodash';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { getLocale } from 'shared/DatePicker/constants';
import { getProfessions, getQualifications, getWorkingPlaces, getDepartmentShifts } from '../../actions';

const WorkerModalForm = ({
  t,
  setData,
  companyId,
  showModal,
  handleSave,
  handleClose,
  departments,
  initialData = {},
  companiesOptions,
  customDepartment,
}) => {
  const modalRef = createRef(null);
  const [formData, setFormData] = useState(initialData);
  const [formHelpers, setFormHelpers] = useState({
    professionList: [],
    workingPlacesList: [],
    qualificationsList: [],
    revealPassword: false,
    department_shifts: [],
  });
  const [formErrors, setFormErrors] = useState({
    formValid: true,
    userNotCreatedError: '',
  });
  const [isLoadingShifts, setIsLoadingShifts] = useState(true);

  useEffect(() => {
    if (showModal && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [!formData?.create_user]);

  const handleChange = (value, key) => {
    if (key === 'is_cooperant' && value === false) {
      setFormData({
        ...formData,
        [key]: value,
        partner: null,
      });
      setData(value, key);
      setData(null, 'partner');
    } else if (key === 'work_permit' && value === false) {
      setFormData({
        ...formData,
        [key]: value,
        work_permit_valid_until: null,
      });
      setData(value, key);
      setData(null, 'work_permit_valid_until');
    } else {
      setFormData({
        ...formData,
        [key]: value,
      });
      setData(value, key);
    }
  };

  const fetchWorkingPlaces = async () => {
    if (formData?.location_department?.id) {
      await getWorkingPlaces(formData?.location_department?.id)
        .then((resp) => setFormHelpers((prevState) => ({ ...prevState, workingPlacesList: get(resp, 'data.results', []) })))
        .catch((error) => console.error(error));
    }
  };

  const fetchQualifications = async () => {
    await getQualifications(companyId)
      .then((resp) => setFormHelpers((prevState) => ({ ...prevState, qualificationsList: get(resp, 'data.results', []) })))
      .catch((error) => console.error(error));
  };

  const fetchProfessions = async () => {
    await getProfessions(companyId)
      .then((resp) => setFormHelpers((prevState) => ({ ...prevState, professionList: get(resp, 'data.results', []) })))
      .catch((error) => console.error(error));
  };

  const fetchDepartmentShifts = () => {
    setIsLoadingShifts(true);
    setFormHelpers((prevState) => ({ ...prevState, department_shifts: [] }));

    let apiFilters = '&limit=9999&order_by=department__name';

    if (formData?.location_department) {
      apiFilters += `&department=${
        typeof formData?.location_department === 'object'
          ? formData?.location_department?.id
          : formData?.location_department
      }`;
    }

    getDepartmentShifts(companyId, apiFilters)
      .then((res) => {
        setFormHelpers((prevState) => ({ ...prevState, department_shifts: get(res, 'data.results', []) }));
        setIsLoadingShifts(false);
      })
      .catch(() => {
        setFormHelpers((prevState) => ({ ...prevState, department_shifts: [] }));
        setIsLoadingShifts(false);
      });
  };

  useEffect(() => {
    fetchProfessions();
    fetchQualifications();
  }, []);

  useEffect(() => {
    fetchWorkingPlaces();
    fetchDepartmentShifts();
  }, [formData?.location_department]);

  const handleCloseModal = () => {
    setFormData({});
    handleClose();
  };

  const onChangeAttachments = (e) => {
    if (e.target.files && e.target.files[0]) {
      // setProfilePicturePreview(URL.createObjectURL(e.target.files[0]));
      handleChange(e.target.files[0], 'profile_picture');
    }
  };

  const requiredSign = <span style={{ margin: '0 5px' }}> *</span>;

  const handleSaveWorker = () => {
    const requiredKeyValues = [
      'name',
      'last_name',
      'date_of_birth',
      'address',
      'mobile_phone',
      'tax_id',
      `${customDepartment ? 'location_department' : 'department'}`,
      'working_place',
      'qualification',
      'gender',
      'profession',
      'active_from',
      'transport_fee',
      'department_shift',
    ];
      // 'food_fee',
    if (formData?.is_cooperant) requiredKeyValues.push('partner');
    if (formData?.work_permit) requiredKeyValues.push('work_permit_valid_until');

    let isValid = formData !== null && formData !== undefined;
    if (isValid) {
      requiredKeyValues.forEach((key) => {
        if (formData[key] === '' || formData[key] === null || formData[key] === undefined) {
          isValid = false;
        }
      });
    }
    setFormErrors((prevState) => ({
      ...prevState,
      formValid: isValid,
    }));
    if (isValid) {
      handleSave((errorMessage) => {
        if (errorMessage) {
          setFormErrors((prevState) => ({
            ...prevState,
            userNotCreatedError: t('page_content.human_resources.workers.user_exists_error'),
          }));
        } else {
          handleClose();
        }
      });
    }
  };

  const genderOptions = [
    { id: 'male', name: t('page_content.human_resources.workers.gender_option_male') },
    { id: 'female', name: t('page_content.human_resources.workers.gender_option_female') },
    { id: 'other', name: t('page_content.human_resources.workers.gender_option_other') },
  ];

  return (
    <Modal
      title={formData ?
        t('page_content.human_resources.workers.edit_worker_modal_title') :
        t('page_content.human_resources.workers.new_worker_modal_title')}
      isOpen={showModal}
      handleClose={handleCloseModal}
      handleSave={handleSaveWorker}
      disableSave={formErrors?.userNotCreatedError}
      error={!formErrors?.formValid ? t('page_content.human_resources.workers.error_msg') : formErrors?.userNotCreatedError || ''}
    >
      <div className="custom_data_modal_container">
        <span style={{ fontSize: 12 }}>{requiredSign} {t('page_content.human_resources.workers.mandatory_fields_label')}</span>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.table_column_first_name')}:{requiredSign}</div>
          <div className="right_input">
            <input type="text" defaultValue={formData?.name || ''} onChange={(e) => { handleChange(e.target.value, 'name'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.table_column_last_name')}:{requiredSign} </div>
          <div className="right_input">
            <input type="text" defaultValue={formData?.last_name || ''} onChange={(e) => { handleChange(e.target.value, 'last_name'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.table_column_email')}:</div>
          <div className="right_input">
            <input type="email" defaultValue={formData?.email || ''} onChange={(e) => { handleChange(e.target.value, 'email'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.table_column_private_email')}: </div>
          <div className="right_input">
            <input type="email" defaultValue={formData?.private_email || ''} onChange={(e) => { handleChange(e.target.value, 'private_email'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_birthday')}:{requiredSign}</div>
          <div className="right_input">
            <DatePicker
              className="datepicker_input"
              dateFormat="dd.MM.yyyy"
              selected={formData?.date_of_birth ? new Date(formData.date_of_birth) : null}
              onChange={(date) => handleChange(date ? moment(date).format('YYYY-MM-DD') : null, 'date_of_birth')}
              maxDate={new Date()}
              locale={getLocale(t)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_gender')}:{requiredSign}</div>
          <Select
            className="right_select"
            options={genderOptions}
            menuPosition="fixed"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.input_label_gender_placeholder')}
            onChange={(e) => handleChange(e.id, 'gender')}
            isSearchable
            value={genderOptions.find((d) => d.id === (formData?.gender || '')) || null}
            styles={selectModalStyles}
          />
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.table_column_address')}:{requiredSign}</div>
          <div className="right_input">
            <input type="text" defaultValue={formData?.address || ''} onChange={(e) => { handleChange(e.target.value, 'address'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_mobile')}:{requiredSign}</div>
          <div className="right_input">
            <input type="tel" defaultValue={formData?.mobile_phone || ''} onChange={(e) => { handleChange(e.target.value, 'mobile_phone'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_tax')}:{requiredSign}</div>
          <div className="right_input">
            <input type="number" defaultValue={formData?.tax_id || ''} onChange={(e) => { handleChange(e.target.value, 'tax_id'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.table_column_department')}:{requiredSign}</div>
          <Select
            className="right_select"
            options={departments?.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            placeholder={t('page_content.human_resources.workers.input_label_department_placeholder')}
            onChange={(e) => handleChange(e, customDepartment || 'department')}
            isSearchable
            value={departments.find((d) => d.id === (customDepartment ? (formData?.location_department ? formData?.location_department?.id : '') : formData?.department?.id))}
            styles={selectModalStyles}
          />
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.default_shift')}:{requiredSign}</div>
          <Select
            className="right_select"
            options={formHelpers?.department_shifts}
            getOptionLabel={(option) => `${option?.name} (${moment(option?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(option?.end_time, 'HH:mm:ss').format('HH:mm')})`}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            placeholder={t('page_content.human_resources.workers.input_label_shifts_placeholder')}
            onChange={(e) => handleChange(e, 'department_shift')}
            isSearchable
            value={formHelpers?.department_shifts?.find((d) => d.id === (typeof formData?.department_shift === 'object' ? formData?.department_shift?.id : formData?.department_shift)) || null}
            styles={selectModalStyles}
            isDisabled={!formData?.location_department}
            isLoading={isLoadingShifts}
            loadingMessage={() => <ContentLoader />}
          />
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_workplace')}:{requiredSign}</div>
          <Select
            className="right_select"
            options={formHelpers?.workingPlacesList?.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            placeholder={(!formData?.department && !formData?.location_department) ?
              t('page_content.human_resources.workers.input_label_workplace_placeholder_disabled') :
              t('page_content.human_resources.workers.input_label_workplace_placeholder')}
            onChange={(e) => handleChange(e, 'working_place')}
            isSearchable
            isDisabled={(!formData?.department && !formData?.location_department)}
            value={formHelpers?.workingPlacesList?.find((d) => d.id === formData?.working_place?.id) || null}
            styles={selectModalStyles}
          />
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_qualifications')}:{requiredSign}</div>
          <Select
            className="right_select"
            options={formHelpers?.qualificationsList?.sort((a, b) => a.name.localeCompare(b.name))}
            menuPosition="fixed"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.input_label_qualification_placeholder')}
            onChange={(e) => handleChange(e, 'qualification')}
            isSearchable
            value={formHelpers?.qualificationsList?.find((d) => d.id === formData?.qualification?.id) || null}
            styles={selectModalStyles}
          />
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_profession')}:{requiredSign}</div>
          <Select
            className="right_select"
            options={formHelpers?.professionList?.sort((a, b) => a.name.localeCompare(b.name))}
            menuPosition="fixed"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.input_label_profession_placeholder')}
            onChange={(e) => handleChange(e, 'profession')}
            isSearchable
            value={formHelpers?.professionList?.find((d) => d.id === formData?.profession?.id) || null}
            styles={selectModalStyles}
          />
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_is_cooperant')}</div>
          <div className="right_input">
            <input type="checkbox" checked={formData?.is_cooperant || false} onChange={(e) => handleChange(e.target.checked, 'is_cooperant')} />
          </div>
        </div>
        {
          formData?.is_cooperant && (
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.input_label_company')}:{formData?.is_cooperant && requiredSign}</div>
              <Select
                className="right_select"
                options={companiesOptions}
                menuPosition="fixed"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t('page_content.human_resources.workers.input_label_company_placeholder')}
                onChange={(e) => handleChange(e || '', 'partner')}
                isSearchable
                isDisabled={!formData?.is_cooperant}
                value={companiesOptions?.find((option) => option.id === formData?.partner?.id || null)}
                styles={selectModalStyles}
              />
            </div>
          )
        }
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_start_of_work')}:{requiredSign}</div>
          <div className="right_input">
            <DatePicker
              className="datepicker_input"
              dateFormat="dd.MM.yyyy"
              selected={formData?.active_from ? new Date(formData.active_from) : null}
              onChange={(date) => handleChange(date ? new Date(date).toISOString() : null, 'active_from')}
              locale={getLocale(t)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_transport_amount')}: *</div>
          <div className="right_input">
            <input type="number" defaultValue={formData?.transport_fee || ''} onChange={(e) => { handleChange(e.target.value, 'transport_fee'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_food_amount')}: *</div>
          <div className="right_input">
            <input type="number" defaultValue={formData?.food_fee || ''} onChange={(e) => { handleChange(e.target.value, 'food_fee'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_info')}: </div>
          <div className="right_textarea">
            <textarea defaultValue={formData?.note || ''} onChange={(e) => handleChange(e.target.value, 'note')} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_work_permit')}:</div>
          <div className="right_input">
            <input type="checkbox" checked={formData?.work_permit || false} onChange={(e) => handleChange(e.target.checked, 'work_permit')} />
          </div>
        </div>
        {
          formData?.work_permit && (
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.input_label_work_permit_valid_until')}:{requiredSign}</div>
              <div className="right_input">
                <DatePicker
                  className="datepicker_input"
                  dateFormat="dd.MM.yyyy"
                  selected={formData?.work_permit_valid_until ? new Date(formData.work_permit_valid_until) : null}
                  onChange={(date) => handleChange(date ? moment(date).format('YYYY-MM-DD') : null, 'work_permit_valid_until')}
                  locale={getLocale(t)}
                />
              </div>
            </div>
          )
        }
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.workers.input_label_profile_picture')}:</div>
          <div className="right_profile_picture">
            <div className="input_container">
              <input type="file" accept="image/*" onChange={onChangeAttachments} />
            </div>
          <div className="profile_picture_preview">
            {(formData?.profile_picture?.full_size || formData?.profile_picture instanceof Blob || formData?.profile_picture instanceof File) &&
              <Button type="delete" onClick={() => handleChange(null, 'profile_picture')}><IconRemove width="15px" height="15px" /></Button>}
            <img
              alt=""
              className="profile_picture_style"
              src={initialData && formData?.profile_picture?.full_size ? formData?.profile_picture?.full_size : (formData?.profile_picture instanceof Blob || formData?.profile_picture instanceof File ? URL.createObjectURL(formData?.profile_picture) : userGreen)}
            />
            </div>
          </div>
        </div>
        {!initialData &&
          <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.workers.input_label_create_user')}<div style={{ marginLeft: 5 }}>
              <Tooltip
                id="tooltip-zoom"
                trigger={['hover']}
                placement="left"
                overlay={t('page_content.human_resources.workers.input_label_create_user_tooltip')}
                overlayClassName="where-filter-tooltip"
              >
                <span aria-describedby="tooltip-zoom">
                  <IconInfo color="#2e86de" height="15px" width="17px" />
                </span>
              </Tooltip>
            </div>
            </div>
            <div className="right_input">
              <input type="checkbox" checked={formData?.create_user || false} onChange={(e) => handleChange(e.target.checked, 'create_user')} />
            </div>
          </div>}
        {formData?.create_user &&
          <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.workers.input_label_password')}: </div>
            <div className="right_input_password">
              <input type={formHelpers?.revealPassword ? 'text' : 'password'} defaultValue="" onChange={(e) => { handleChange(e.target.value, 'password'); }} />
              <div className="icon_show_hide_password" onClick={() => setFormHelpers((prevState) => ({ ...prevState, revealPassword: !prevState.revealPassword }))}>
                {!formHelpers?.revealPassword ? <IconShowPassword height="20px" width="20px" /> : <IconHidePassword height="20px" width="20px" />}
              </div>
            </div>
          </div>}
        <div ref={modalRef} />
      </div>
    </Modal>
  );
};

WorkerModalForm.propTypes = {
  initialData: PropTypes.object,
  setData: PropTypes.func,
  t: PropTypes.func,
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  customDepartment: PropTypes.string,
  companyId: PropTypes.number,
  companiesOptions: PropTypes.array,
  departments: PropTypes.array,
};

export default WorkerModalForm;
