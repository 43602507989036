import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import view1 from 'shared/Icons/workforce/list-view-active.png';
import view2 from 'shared/Icons/workforce/grid-view-active.png';
import userGreen from 'shared/Icons/workforce/user-green.png';
import { IconCloudDownload, IconPopup } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import { ContentLoader } from 'shared';
import './styles.scss';

import { getAllDocumentTypes, getWorkerDetails, getWorkerDocuments } from './actions';

import ManageDocumentsModal from './components/ManageDocumentsModal/ManageDocumentsModal';
import PDFPreviewModal from '../IndustryProjectDetails/components/PDFPreviewModal';
import AllContractsModal from './components/AllContractsModal/AllContractsModal';
import WorkingInjuriesTab from './components/WorkingInjuriesTab';
import WorkerSchedulesTab from './components/WorkerSchedulesTab';
import GeneralInfoTab from './components/GeneralInfoTab/index';
import MyForms from './components/MyForms/MyForms';

const IndustryLocationWorkerDetails = ({ locationId, companyId, t, history, currentUser, showWorkingInjuries }) => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [workerId, setWorkerId] = useState();
  const [workerDetails, setWorkerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);
  const [pdfPreviewModalState, setPdfPreviewModalState] = useState(false);
  const [pdfPreviewModalObject, setPdfPreviewModalObject] = useState(null);
  const [showAllContracts, setShowAllContracts] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [contractsTableData, setContractsTableData] = useState({
    contract: [],
    isLoading: true,
  });
  const [lastContract, setLastContract] = useState({});

  const fetchWorkersList = () => {
    getWorkerDetails(locationId, companyId, workerId)
      .then((resp) => {
        const worker = get(resp, 'data', []);
        if (worker) {
          setWorkerDetails(worker);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const fetchDocumentTypes = async () => {
    const res = await getAllDocumentTypes(companyId);
    setDocumentTypes(get(res, 'data.results', []));
  };

  const fetchContracts = async () => {
    setContractsTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const filters = '&is_mid=true&order_by=-updated_at';
    const res = await getWorkerDocuments(companyId, workerId, filters);
    const contractsData = get(res, 'data.results', []);
    setLastContract(contractsData[0]);
    setContractsTableData({
      contracts: contractsData,
      isLoading: false,
    });
  };

  useEffect(() => {
    if (documentTypes?.length) {
      fetchContracts();
    }
  }, [documentTypes]);

  useEffect(() => {
    const hrRole = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase() === 'hr');

    if (hrRole || currentUser?.is_admin) {
      setCurrentUserRole('HR');
      setIsReadOnly(false);
    } else {
      const voditeljRole = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase().includes('voditelj'));

      if (voditeljRole) {
        setCurrentUserRole('Voditelj');
        setIsReadOnly(true);
      } else {
        setIsReadOnly(true);
      }
    }

    fetchDocumentTypes();
  }, [currentUser]);

  useEffect(() => {
    const getWorkerId = () => {
      const url = window.location.href;
      const urlParts = url.split('/');
      setWorkerId(parseInt(urlParts[urlParts.length - 1], 10));
    };

    if (!workerId) {
      getWorkerId();
    } else {
      fetchWorkersList();
    }
  }, [workerId]);

  const closePDFPreviewModal = () => {
    setPdfPreviewModalObject({});
    setPdfPreviewModalState(false);
  };

  const handleClickDownloadDocument = () => {
    const link = document.createElement('a');
    link.href = lastContract.file;
    link.target = '_blank';
    link.setAttribute('download', lastContract.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  if (loading && !workerId) {
    return (
      <div className="loaderWrapper">
        <ContentLoader />
      </div>
    );
  }

  const transformNameOrSurnameString = (string) => {
    if (!string) {
      return '';
    }
    const deconstructedString = string.split(/\s+|-/);
    const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

    return formatedString;
  };

  return (
    <div className="workerDetails_container fullscreen">
      {!isReadOnly &&
        <div className="breadcrumbs">
          <span
            onClick={() => {
              history.push(`/${companyId}/industry/location/${locationId}/human-resources/`);
            }}
          >{t('page_content.workforce_management.worker_details.breadcrumb_workers_list')}</span>
          <p>{'>'}</p>
          <span>{`${transformNameOrSurnameString(workerDetails.name)} ${transformNameOrSurnameString(workerDetails.last_name)}`}</span>
        </div>}

      <div className="info_row">
        <div className="info_card">
          {loading ? <div className="card_loader"><ContentLoader text="" /></div> :
            <div style={{ display: 'flex', gap: 20 }}>
              <div className="leftColumn">
                <img src={workerDetails && workerDetails?.profile_picture?.thumbnail ? workerDetails?.profile_picture?.thumbnail : userGreen} alt="" />
              </div>
              <div className="rightColumn">
                <div className="title">{workerDetails ? `${workerDetails.name} ${workerDetails.last_name}` : ''}</div>
              </div>
            </div>}
        </div>

        <div className="info_card">
          <div className="column">
            <div className="inline">
              <div className="leftColumn">
                <img src={view1} alt="" />
              </div>
              <div className="rightColumn">
                <div className="title">{t('page_content.workforce_management.worker_details.card_work_contract')}</div>
              </div>
            </div>
            <div className="date">
              {t('page_content.workforce_management.worker_details.card_last_updated')}: {moment(lastContract?.created_at).format(defaultDateFormat)}
            </div>
            <div className="icons">
              <div className="icon_button" onClick={() => setShowAllContracts(true)}>
                <IconPopup height="15px" width="15px" /> {t('page_content.workforce_management.worker_details.all_contracts_button')}
              </div>
              <Tooltip
                trigger={!lastContract ? 'click' : 'none'}
                placement="top"
                overlay={<div>{t('page_content.workforce_management.worker_details.tooltip_message_download')}</div>}
                overlayClassName="tooltip_overlay"
              >
                <div className={`${!lastContract ? 'icon_button disabled_icon_button' : 'icon_button'}`} onClick={lastContract && handleClickDownloadDocument}>
                  <IconCloudDownload height="15px" width="15px" color={`${!lastContract ? '#979797' : '#555'}`} /> {t('page_content.workforce_management.worker_details.download_document_button')}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

      <div className="info_card">
        <div className="column">
          <div className="inline">
            <div className="leftColumn">
              <img src={view2} alt="" />
            </div>
            <div className="rightColumn">
              <div className="title">{t('page_content.workforce_management.worker_details.card_other_documentations')}</div>
            </div>
          </div>
          <div className="icons">
            <div className="icon_button" onClick={() => setShowDocumentationModal(true)}>
              <IconPopup height="15px" width="15px" color="#555" /> {t('page_content.workforce_management.worker_details.manage_documents_button')}
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="tab_pannels">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.workforce_management.worker_details.tab_general')}</Tab>
            {currentUserRole !== 'Voditelj' && <Tab>{t('page_content.workforce_management.worker_details.tab_my_forms')}</Tab>}
            {showWorkingInjuries && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
            <Tab>{t('page_content.workforce_management.worker_details.worker_scheduler')}</Tab>
          </TabList>
          <TabPanel>
            <GeneralInfoTab
              companyId={companyId}
              isReadOnly={isReadOnly}
              workerDetails={workerDetails}
              transformString={transformNameOrSurnameString}
            />
          </TabPanel>
          {currentUserRole !== 'Voditelj' &&
            <TabPanel>
              <MyForms
                currentUser={currentUser}
                companyId={companyId}
                workerId={workerId}
              />
            </TabPanel>}
            {showWorkingInjuries &&
            <TabPanel>
              <WorkingInjuriesTab
                locationId={locationId}
                workerId={workerId}
              />
            </TabPanel>}

          <TabPanel>
            <WorkerSchedulesTab
              isReadOnly={isReadOnly}
              companyId={companyId}
              workerId={workerId}
              workerDetails={workerDetails}
            />
            </TabPanel>
        </Tabs>
      </div>

      <ManageDocumentsModal
        isReadOnly={isReadOnly}
        showDocumentationModal={showDocumentationModal}
        currentUser={currentUser}
        companyId={companyId}
        handleClose={() => setShowDocumentationModal(false)}
        workerId={workerId}
        refetchDocumentTypes={fetchContracts}
      />

      <AllContractsModal
        showModal={showAllContracts}
        handleCloseModal={() => setShowAllContracts(false)}
        workerId={workerId}
        companyId={companyId}
        tableData={contractsTableData}
        isReadOnly={isReadOnly}
      />

      <PDFPreviewModal
        modalStatus={pdfPreviewModalState}
        pdfObjectInfo={pdfPreviewModalObject}
        closeModal={closePDFPreviewModal}
      />
    </div>
  );
};

IndustryLocationWorkerDetails.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  showWorkingInjuries: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationWorkerDetails)));
