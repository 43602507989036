import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { redirectToHomePage } from 'industry/helpers';
import { ContentLoader } from 'shared';
import api from 'helpers/api';
import DepartmentsTab from './components/Departments/DepartmentsTab';
import WorkersList from './components/Workers/WorkersList';
import WorkingInjuries from './components/WorkingInjuries';
import Layoffs from './components/Layoffs/Layoffs';
import Ratings from './components/Ratings/Ratings';
import Shifts from './components/Shifts/Shifts';
import Forms from './components/Forms/Forms';
import './styles.scss';
import ControlPanel from './components/ControlPanel/index';
import AssignedEquipment from './components/Assigned equipment/index';

const IndustryLocationHumanResources = ({ currentUser, companyId, locationId, t, customDepartment, history, showWorkingInjuries, hrTabs }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [department, setDepartment] = useState(null);

  const checkRoles = () => {
    setIsLoading(true);

    if (currentUser && !currentUser?.is_admin) {
      const hrRole = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase() === 'hr');

      if (hrRole) {
        setCurrentUserRole('HR');
        setIsLoading(false);
      } else {
        api.get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}&user=${currentUser?.id}`)
          .then((response) => {
            const workerId = get(response, 'data.results[0].id', null);
            if (workerId) {
              // Make an API call to check if the user is a manager or deputy manager
              api.get(`/api/v1/workforce/department_managers/?location=${locationId}&worker=${workerId}&order_by=-id&is_active=true`)
                .then((data) => {
                  const results = get(data, 'data.results', null);
                  const isManager = results.some((manager) => manager.is_manager || manager.is_deputy_manager);
                  if (isManager) {
                    setCurrentUserRole('Voditelj');
                  } else {
                    const currentUrl = window.location.href;
                    const newUrl = currentUrl.endsWith('/') ? `${currentUrl}worker/${workerId}` : `${currentUrl}/worker/${workerId}`;
                    window.location.href = newUrl;
                  }
                })
                .catch((error) => {
                  console.error('Error checking manager status:', error);
                  redirectToHomePage(companyId, locationId);
                });
            } else {
              redirectToHomePage(companyId, locationId);
            }
          })
          .catch(() => {
            redirectToHomePage(companyId, locationId);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    // TODO: staviti neki company/locaiton config s kojim cemo toglati dal da se provjere role ili klasicni check
    checkRoles();
  }, [companyId, locationId]);

  useEffect(() => {
    setSelectedTab(history?.location?.state?.tabIndex || 0);
  }, []);

  const filterByDepartment = (value) => {
    setSelectedTab(0);
    setDepartment(value);
  };

  const handleSelectTab = (e) => {
    setSelectedTab(e);
    setDepartment(null);
  };

  const shouldDisplayTab = (tabName) => {
    const defaultHrTabs = hrTabs || [{ name: 'Workers' }];
    return defaultHrTabs.some((o) => o.name === tabName);
  };

  const userHaveFullAccess = currentUserRole === 'HR' || currentUser.is_admin;

  if (isLoading) {
    <ContentLoader />;
  }
  return (
    <div className="industry-tab human_resources_container fullscreen">
      <Tabs selectedIndex={selectedTab} onSelect={handleSelectTab}>
        <TabList>
          {shouldDisplayTab('Workers') && <Tab>{t('page_content.human_resources.workers_tab')}</Tab>}
          {shouldDisplayTab('Working injuries') && showWorkingInjuries && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
          {shouldDisplayTab('Departments') && <Tab>{t('page_content.human_resources.departments_tab')}</Tab>}
          {shouldDisplayTab('Shifts') && <Tab>{t('page_content.human_resources.shifts_tab')}</Tab>}
          {shouldDisplayTab('Layoffs') && <Tab>{t('page_content.human_resources.layoffs_tab')}</Tab>}
          {shouldDisplayTab('Assigned equipment') && <Tab>{t('page_content.human_resources.assigned_equipment_tab')}</Tab>}
          {shouldDisplayTab('Forms') && <Tab>{t('page_content.human_resources.forms_tab')}</Tab>}
          {shouldDisplayTab('Ratings') && <Tab>{t('page_content.human_resources.ratings_tab')}</Tab>}
          {shouldDisplayTab('Control panel') && <Tab>{t('page_content.human_resources.control_panel.parent_tab_control_panel')}</Tab>}
        </TabList>

        {
         shouldDisplayTab('Workers') &&
          <TabPanel>
            <WorkersList
              department={department}
              locationId={locationId}
              companyId={companyId}
              customDepartment={customDepartment}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>
        }

        {shouldDisplayTab('Working injuries') && showWorkingInjuries &&
          <TabPanel>
            <WorkingInjuries
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>}

        {shouldDisplayTab('Departments') &&
          <TabPanel>
            <DepartmentsTab
              handleShowWorkers={filterByDepartment}
              companyId={companyId}
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>}

        {shouldDisplayTab('Shifts') &&
          <TabPanel>
            <Shifts
              companyId={companyId}
              locationId={locationId}
            />
          </TabPanel>}

        {shouldDisplayTab('Layoffs') &&
          <TabPanel>
            <Layoffs
              companyId={companyId}
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>}

          {shouldDisplayTab('Assigned equipment') &&
          <TabPanel>
            <AssignedEquipment
              companyId={companyId}
              locationId={locationId}
              currentUser={currentUser}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>}

        {shouldDisplayTab('Forms') &&
          <TabPanel>
            <Forms
              companyId={companyId}
              locationId={locationId}
            />
          </TabPanel>}

        {shouldDisplayTab('Ratings') &&
          <TabPanel>
            <Ratings
              companyId={companyId}
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>}

          {shouldDisplayTab('Control panel') &&
          <TabPanel>
            <ControlPanel
              companyId={companyId}
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />
          </TabPanel>}
      </Tabs>
    </div>
  );
};

IndustryLocationHumanResources.propTypes = {
  hrTabs: PropTypes.array,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  customDepartment: PropTypes.string,
  showWorkingInjuries: PropTypes.bool,
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state) {
  return {
    currentUser: get(state, 'currentUser'),
    companyId: get(state, 'app.company.id'),
    locationId: get(state, 'app.location.id'),
    customDepartment: get(state, 'app.companyConfig.config.custom_department'),
    checkHRAccessByRole: get(state, 'app.companyConfig.config.check_hr_by_role', false),
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
  };
}

export default connect(mapStateToProps)(withRouter(withTranslation()(IndustryLocationHumanResources)));
