import api from 'helpers/api';
import { Notification } from 'shared';

export const getEmployments = async (companyId, filters = '') => {
  const url = `/api/v1/workforce/employments/?company=${companyId}${filters}&limit=30`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching employments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedEmployments = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching employments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addEmployment = async (data) => {
  const url = '/api/v1/workforce/employments/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding employment', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editEmployment = async (data, id, companyId) => {
  const url = `/api/v1/workforce/employments/${id}/?company=${companyId}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while editing employment', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getTerminationReasons = async (companyId) => {
  const url = `/api/v1/workforce/termination_reasons/?company=${companyId}&limit=9999`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching termination reasons', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addTerminationReasons = async (data) => {
  const url = '/api/v1/workforce/termination_reasons/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching termination reasons', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllWorkers = async (companyId, locationId) => {
  const url = `/api/v1/workforce/workers/?company=${companyId}&location=${locationId}&limit=9999`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDocumentTypes = async (companyId) => {
  const url = `/api/v1/workforce/document_types/?company=${companyId}&limit=9999`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching document types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDocuments = async (companyId) => {
  const url = `/api/v1/workforce/documents/?company=${companyId}&limit=9999`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching documents', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteDocument = async (companyId, id) => {
  const url = `/api/v1/workforce/documents/${id}/?company=${companyId}`;
  try {
    const res = await api.delete(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting document', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerDocuments = async (companyId, workerId) => {
  const url = `/api/v1/workforce/documents/?company=${companyId}&worker=${workerId}&limit=9999`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching documents', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const uploadDocument = async (documentData) => {
  try {
    return await api.post('/api/v1/workforce/documents/', documentData);
  } catch (error) {
    return Notification('error', 'An error occurred while adding document', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editDocument = async (documentData, id, companyId) => {
  try {
    return await api.patch(`/api/v1/workforce/documents/${id}/?company=${companyId}`, documentData);
  } catch (error) {
    return Notification('error', 'An error occurred while adding document', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getForms = async (companyId) => {
  const url = `/api/v1/surveys/surveys/?limit=15&company=${companyId}&order_by=-updated_at`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting forms', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getQuestionOptions = async (questionId) => {
  const url = `/api/v1/surveys/question_options/?question=${questionId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting question options', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getQuestions = async (formId) => {
  const url = `/api/v1/surveys/questions/?survey=${formId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting questions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getSurveyAnswers = async (survey) => {
  const url = `/api/v1/surveys/worker_answers/?worker_survey=${survey}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting question options', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getUploadedFiles = async (companyId, workerSurveyId) => {
  const url = `/api/v1/surveys/survey_files/?company=${companyId}&worker_survey=${workerSurveyId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteUploadedFile = async (id) => {
  const url = `/api/v1/surveys/survey_files/${id}`;
  try {
    const res = await api.delete(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const finishSurvey = async (data) => {
  const url = '/api/v1/surveys/finish_survey/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveSurveyFiles = async (data) => {
  const url = '/api/v1/surveys/survey_files/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving files', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveWorkerAnswer = async (data) => {
  const url = '/api/v1/surveys/submit_answer/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving worker answer', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const newWorkerSurvey = async (data) => {
  const url = '/api/v1/surveys/worker_surveys/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving worker survey', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerSurveys = async (workerId, companyId) => {
  const url = `/api/v1/surveys/worker_surveys/?worker=${workerId}&company=${companyId}&order_by=-updated_at`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving worker survey', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
