import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { modalSizes } from 'shared/constants';
import { getWorkersList, addWorkingInjuries, editWorkingInjuries } from '../../actions';
import '../../styles.scss';

const WorkingInjuriesModal = ({ t, companyId, locationId, initialValues = {}, isOpen, handleCloseModal, closeModalAndRefetch, statusesOptions, eventLocationOptions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [workersQuery, setWorkersQuery] = useState('');
  const [workers, setWorkers] = useState({
    isLoading: false,
    data: [],
  });
  const debounceTimeoutRef = useRef(null);

  const fetchWorkers = () => {
    setWorkers((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const urlFilters = `&first_or_last_name=${workersQuery}`;

    getWorkersList(locationId, companyId, urlFilters)
      .then((res) => {
        setWorkers((prevState) => ({
          ...prevState,
          isLoading: false,
          data: get(res, 'data.results', []),
        }));
      })
      .catch((e) => console.error('Error while fetching workers', e));
  };

  useEffect(() => {
    if (workersQuery.length > 2) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchWorkers(workersQuery);
      }, 300);
    }
  }, [workersQuery]);

  const handleWorkersSearch = (value) => {
    setWorkersQuery(value);
  };

  const onChange = (value, key) => {
    if (key === 'worker' || key === 'responsible_person') {
      setWorkers({
        isLoading: false,
        data: [],
      });
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      shift: formValues?.shift?.id || null,
      worker: formValues?.worker?.id || null,
      responsible_person: formValues?.responsible_person?.id || null,
      location: locationId,
    };

    if (formValues?.id) {
      await editWorkingInjuries(locationId, formValues?.id, payload);
    } else {
      await addWorkingInjuries(locationId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      size={modalSizes.large}
      handleClose={handleCloseModal}
      handleSave={handleSave}
      disableSave={!formValues?.worker || !formValues?.date || !formValues?.event_location || !formValues?.responsible_person}
      isOpen={isOpen}
    >
      {
        isLoading ? <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.worker')}*:</div>
              <div className="right_select">
                <Select
                  options={workers?.data}
                  getOptionLabel={(user) => `${user.name} ${user.last_name}`}
                  getOptionValue={(user) => user}
                  isClearable
                  placeholder={t('page_content.human_resources.injuries.search_worker_by_name')}
                  menuPosition="fixed"
                  onInputChange={(e) => handleWorkersSearch(e)}
                  onChange={(e) => onChange(e || '', 'worker')}
                  value={formValues?.worker || ''}
                  styles={selectModalStyles}
                  isLoading={workers?.isLoading}
                  loadingMessage={() => <ContentLoader />}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.date')}*:</div>
              <div className="right_datePicker">
                <ReactDatePicker
                  placeholderText=""
                  dateFormat="dd.MM.yyyy"
                  selected={get(formValues, 'date', '') ? moment(get(formValues, 'date', '')).toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date')}
                  showTimeSelect={false}
                  disabledKeyboardNavigation
                  locale={getLocale(t)}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.event_location')}*:</div>
              <div className="right_select">
                <Select
                  options={eventLocationOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  isClearable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(e) => onChange(e?.value || '', 'event_location')}
                  value={(formValues?.event_location ? eventLocationOptions.find((sOption) => (sOption.value === formValues.event_location)) : '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.injured_body_part')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'injured_body_part', '')} onChange={(e) => onChange(e.target.value, 'injured_body_part')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.injury_source')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'injury_source', '')} onChange={(e) => onChange(e.target.value, 'injury_source')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.injury_origin')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'injury_origin', '')} onChange={(e) => onChange(e.target.value, 'injury_origin')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.activity_undertaken')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'activity_undertaken', '')} onChange={(e) => onChange(e.target.value, 'activity_undertaken')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.planned_resolution_date')}:</div>
              <div className="right_datePicker">
                <ReactDatePicker
                  placeholderText=""
                  dateFormat="dd.MM.yyyy"
                  selected={get(formValues, 'planned_resolution_date', '') ? moment(get(formValues, 'planned_resolution_date', '')).toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'planned_resolution_date')}
                  showTimeSelect={false}
                  disabledKeyboardNavigation
                  locale={getLocale(t)}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.status')}:</div>
              <div className="right_select">
                <Select
                  options={statusesOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  isClearable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(e) => onChange(e?.value || '', 'status')}
                  value={(formValues?.status ? statusesOptions.find((sOption) => (sOption.value === formValues.status)) : '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.injuries.responsible_person')}*:</div>
              <div className="right_select">
                <Select
                  options={workers?.data}
                  getOptionLabel={(user) => `${user.name} ${user.last_name}`}
                  getOptionValue={(user) => user}
                  isClearable
                  placeholder={t('page_content.human_resources.injuries.search_worker_by_name')}
                  menuPosition="fixed"
                  onInputChange={(e) => handleWorkersSearch(e)}
                  onChange={(e) => onChange(e || '', 'responsible_person')}
                  value={formValues?.responsible_person || ''}
                  styles={selectModalStyles}
                  isLoading={workers?.isLoading}
                  loadingMessage={() => <ContentLoader />}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

WorkingInjuriesModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  statusesOptions: PropTypes.array.isRequired,
  eventLocationOptions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(WorkingInjuriesModal));
