import { get } from 'lodash';
import moment from 'moment';

export const groupsTransformer = (data, treeLevel = 1, parent_id = null) => {
  const sortedData = data ? [...data].sort((a, b) => a.id - b.id).map((item) => ({ ...item })) : [];

  if (sortedData && sortedData.length > 0) {
    for (let i = 0; i < sortedData.length; i++) {
      sortedData[i].title = get(sortedData[i], 'external_id', '');
      sortedData[i].treeLevel = treeLevel;
      if (parent_id) {
        sortedData[i].parent_id = parent_id;
      }
    }

    return sortedData;
  }
  return [];
};

export const ordersTransformer = (data, treeLevel = 1, parent_id = null) => {
  const spreadedData = data ? [...data].map((item) => ({ ...item })) : [];

  if (spreadedData && spreadedData.length > 0) {
    for (let i = 0; i < spreadedData.length; i++) {
      spreadedData[i].treeLevel = treeLevel;
      if (parent_id) {
        spreadedData[i].parent_id = parent_id;
      }
      spreadedData[i].group = get(spreadedData[i], 'id', null);
      spreadedData[i].title = get(spreadedData[i], 'external_id', '');
      spreadedData[i].start_time = get(spreadedData[i], 'gantt_start') && get(spreadedData[i], 'gantt_stop')
        ? moment(get(spreadedData[i], 'gantt_start')).valueOf() : '';

      spreadedData[i].end_time = get(spreadedData[i], 'gantt_start') && get(spreadedData[i], 'gantt_stop')
        ? moment(get(spreadedData[i], 'gantt_stop')).valueOf() : '';

      spreadedData[i].canMove = true;
      spreadedData[i].canResize = false;
      spreadedData[i].canChangeGroup = false;
    }
    return spreadedData;
  }
  return [];
};

export const defaultHeaderFormats = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY',
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'dddd, LL',
    mediumLong: 'dddd, LL',
    medium: 'dd D',
    short: 'D',
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
  second: {
    long: 'mm:ss',
    mediumLong: 'mm:ss',
    medium: 'mm:ss',
    short: 'ss',
  },
};
