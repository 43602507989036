/* eslint-disable react/jsx-indent */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'shared';
import { IconEdit, IconComment } from 'shared/Icons';
import '../style.scss';

class ProductionRecordsTable extends Component {
  displayUser = (user) => {
    return (user.first_name !== '' && user.last_name !== '')
      ? `${user.first_name} ${user.last_name}`
      : `${user.email}`;
  }

  displayLog = (log, type) => {
    switch (type) {
      case 'bool':
        return log.value_bool !== null && log.value_bool !== false ? 1 : false;
      case 'numeric':
        return log.value_numeric !== null ? log.value_numeric : false;
      case 'text':
        return log.value_text !== null ? log.value_text : false;
      case 'decimal':
        return log.value_decimal !== null ? log.value_decimal : false;
      case 'time':
        return log.value_time !== null ? moment(log.value_time, 'HH:mm:ss').format('HH:mm') : false;
      case 'json':
        return log.value_json !== null ? (
          <Tooltip
            id="tooltip-comment"
            trigger={['hover']}
            placement="left"
            overlay={
              <pre>
                {`[\n${log.value_json.reduce((acc, obj) => {
                  const objStr = JSON.stringify(obj);
                  const lastChar = acc.slice(-1);
                  if (lastChar === '}') {
                    return `${acc},\n${objStr}`;
                  } else {
                    return `${acc}${objStr}`;
                  }
                }, '')}\n]`}
              </pre>
            }
            overlayClassName="where-filter-tooltip"
          >
            <span aria-describedby="tooltip-comment">
              <IconComment height="18px" width="20px" />
            </span>
          </Tooltip>
        ) : false;
      default:
        return false;
    }
  }

  render() {
    const {
      theadData,
      tbodyData,
      t,
      next,
      previous,
      fetchByUrl,
      handleSignRecord,
      signatoryRoles,
      currentUser,
      handleRecordRowClick,
    } = this.props;
    return (
      <Fragment>
        <table id="production_records">
          <thead>
            <tr>
              <th>
                {t('page_content.production_records.time')}
              </th>
              <th>
                {t('page_content.production_records.line')}
              </th>
              {
                theadData?.order_required &&
                <th>
                  {t('page_content.production_records.order')}
                </th>
              }
              {theadData && theadData.entries && theadData.entries.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0)).map((formItem, formItemIdx) => {
                return (<th key={`form_item_${formItemIdx}`}>
                  <span style={{ display: 'inline-block' }}>
                    <strong>{formItem.title}</strong>
                    <br />
                    <small>{formItem.description}</small>
                  </span>
                </th>);
              })}
              {
                theadData.signatory_1_title && theadData.signatory_1_resource !== null ? <th>
                  {theadData.signatory_1_title}
                </th> : null
              }
              {
                theadData.signatory_2_title && theadData.signatory_2_resource !== null ? <th>
                  {theadData.signatory_2_title}
                </th> : null
              }
              {
                theadData.signatory_3_title && theadData.signatory_3_resource !== null ? <th>
                  {theadData.signatory_3_title}
                </th> : null
              }
              <th>
                {t('page_content.production_records.comment')}
              </th>
            </tr>
          </thead>
          <tbody>
            {tbodyData?.map((formLog, formLogIdx) => {
              return (<tr
              className={`${formLog?.production_record?.view_type === 'mes' ? 'clickable' : ''} ${formLog?.is_approved ? 'is_approved_row' : formLog?.is_approved === false ? 'non_approved_row' : ''}`}
              key={`form_log_${formLogIdx}`}
              onClick={() => formLog?.production_record?.view_type === 'mes' ? handleRecordRowClick(formLog) : null}
              >
                <td>
                  {moment(formLog.time_created).format('DD.MM. - HH:mm')}
                </td>
                <td>
                  {formLog.line && formLog.line.name}
                </td>
                {
                  theadData?.order_required &&
                  <td>
                    <span style={{ display: 'inline-block' }}>
                      <strong>{formLog && formLog.order && formLog.order.external_id ? formLog.order.external_id : ''}</strong>
                      <br />
                      <small>{formLog && formLog.order && formLog.order.customer_name ? formLog.order.customer_name : ''}</small>
                    </span>
                  </td>
                }
                {theadData?.entries?.map((formEntry, formEntryIdx) => {
                  const entryLog = formLog.log_entries.find((x) => x.production_record_entry.id === formEntry.id);
                  return (entryLog !== undefined)
                    ? <td key={`form_entry_item_${formEntryIdx}`}>
                      {entryLog.production_record_entry.entry_type === 'bool' || entryLog.production_record_entry.entry_type === 'instruction' ?
                        <input
                          type="checkbox"
                          readOnly
                          checked={this.displayLog(entryLog, entryLog?.production_record_entry?.entry_type)}
                          onClick={() => { return false; }}
                        />
                        : this.displayLog(entryLog, entryLog?.production_record_entry?.entry_type)}
                    </td>
                    : <td key={formEntryIdx} />;
                })}
                {formLog?.production_record?.signatory_1_resource && formLog.production_record.signatory_1_resource !== null &&
                  <td>
                    <Fragment>
                      {formLog.signatory_1 && formLog.signatory_1_time !== null &&
                        <Fragment>
                          {moment(formLog.signatory_1_time).format('DD.MM. - HH:mm')}<br />
                          {this.displayUser(formLog.signatory_1)}
                        </Fragment>}
                      {formLog.production_record.signatory_1_resource?.code && formLog.signatory_1_time === null && formLog.production_record.signatory_1_resource.code === 'records-confirm-entry' && ((signatoryRoles[0] && currentUser.roles && currentUser.roles.some((r) => r.role.id === signatoryRoles[0].id))) ?
                        <div>
                          <Button onClick={() => handleSignRecord(formLog, '1')}>
                            <IconEdit
                              height="14px"
                              width="14px"
                              fill="#4285F4"
                            />
                          </Button>
                        </div> : ''}
                    </Fragment>
                  </td>}
                {formLog?.production_record?.signatory_2_resource && formLog.production_record.signatory_2_resource !== null &&
                  <td>
                    <Fragment>
                      {formLog.signatory_2 && formLog.signatory_2_time !== null &&
                        <Fragment>
                          {moment(formLog.signatory_2_time).format('DD.MM. - HH:mm')}<br />
                          {this.displayUser(formLog.signatory_2)}
                        </Fragment>}
                      {formLog.production_record.signatory_2_resource && formLog.production_record.signatory_2_resource.code
                        && formLog.signatory_2_time === null && formLog.production_record.signatory_2_resource.code === 'records-confirm-entry' && ((signatoryRoles[1] && currentUser.roles && currentUser.roles.some((r) => r.role.id === signatoryRoles[1].id))) ?
                        <div>
                          <Button onClick={() => handleSignRecord(formLog, '2')}>
                            <IconEdit
                              height="14px"
                              width="14px"
                              fill="#4285F4"
                            />
                          </Button>
                        </div> : ''}
                    </Fragment>
                  </td>}
                {formLog?.production_record?.signatory_3_resource && formLog.production_record.signatory_3_resource !== null &&
                  <td>
                    <Fragment>
                      {formLog.signatory_3 && formLog.signatory_3_time !== null &&
                        <Fragment>
                          {moment(formLog.signatory_3_time).format('DD.MM. - HH:mm')}<br />
                          {this.displayUser(formLog.signatory_3)}
                        </Fragment>}
                      {formLog.production_record.signatory_3_resource && formLog.production_record.signatory_3_resource.code
                        && formLog.signatory_3_time === null && formLog.production_record.signatory_3_resource.code === 'records-confirm-entry' && ((signatoryRoles[2] && currentUser.roles && currentUser.roles.some((r) => r.role.id === signatoryRoles[2].id))) ?
                        <div>
                          <Button onClick={() => handleSignRecord(formLog, '3')}>
                            <IconEdit
                              height="14px"
                              width="14px"
                              fill="#4285F4"
                            />
                          </Button>
                        </div> : ''}
                    </Fragment>
                  </td>}
                <td style={{ textAlign: 'center' }}>
                  {formLog ?
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <p style={{ margin: '0 0 8px 0' }}>{formLog && formLog.is_approved === true ? t('page_content.production_records.approved') : formLog && formLog.is_approved === false ? t('page_content.production_records.not_approved') : ''}</p>
                      {
                        formLog.comment_approval && formLog.comment_approval.length ?
                          <Tooltip
                            id="tooltip-comment"
                            trigger={['hover']}
                            placement="left"
                            overlay={formLog.comment_approval}
                            overlayClassName="where-filter-tooltip"
                          >
                            <span
                              aria-describedby="tooltip-comment"
                            >
                              <IconComment
                                height="18px"
                                width="20px"
                              />
                            </span>
                          </Tooltip> : '-'
                      }
                    </div>
                    : '-'}
                </td>
              </tr>);
            })}
          </tbody>
        </table>
        <div className="table_pagination_buttons">
          <Button
            disabled={!previous}
            onClick={() => fetchByUrl(previous)}
          >
            {t('page_content.previous')}
          </Button>
          <Button
            disabled={!next}
            onClick={() => fetchByUrl(next)}
          >
            {t('page_content.next')}
          </Button>
        </div>
      </Fragment>
    );
  }
}

ProductionRecordsTable.propTypes = {
  theadData: PropTypes.object.isRequired,
  tbodyData: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  next: PropTypes.string,
  previous: PropTypes.string,
  fetchByUrl: PropTypes.func.isRequired,
  handleSignRecord: PropTypes.func.isRequired,
  signatoryRoles: PropTypes.array,
  currentUser: PropTypes.object,
  handleRecordRowClick: PropTypes.func,
};

export default withTranslation()(ProductionRecordsTable);
