import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';

import { ordersCreatedAndUpdated } from 'shared/constants';
import { selectStyles } from 'styles/modules/reactSelect';
import { Table, TableButtons, Button } from 'shared';
import { IconPopup } from 'shared/Icons/index';

import { getWorkerSurveys, updateWorkerSurveys, getFormCategories, getSurveys, getPaginatedSurveys } from '../../actions';

import FormModal from './FormModal';

const MyForms = ({ t, workerId, companyId, currentUser, workProcessRatingCategoryCode }) => {
  const [tableData, setTableData] = useState({
    forms: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [refillableTableData, setRefillableTableData] = useState({
    forms: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [isRefill, setIsRefill] = useState(false);

  const [activeForm, setActiveForm] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [formCategories, setFormCategories] = useState(null);

  const fetchUserForms = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    let filters = '';
    if (selectedFilter && selectedFilter?.value === 'not_started') {
      filters += '&is_seen=false';
      filters += '&is_finished=false';
    }
    if (selectedFilter && selectedFilter?.value !== 'not_started') {
      filters = `&${selectedFilter?.value}=true`;
    }
    const res = await getWorkerSurveys(workerId, filters);
    const results = get(res, 'data.results', []);
    const forms = results?.filter((result) => result?.survey?.category !== formCategories?.id);
    setTableData({
      forms,
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      count: get(res, 'data.count'),
      isLoading: false,
    });
  };

  const fetchRefillableForms = async () => {
    setRefillableTableData((prevState) => ({ ...prevState, isLoading: true }));

    await getSurveys(companyId, '&limit=15&who_can_start_survey=worker&is_published=true')
      .then((res) => {
        const results = get(res, 'data.results', []);
        const refillableForms = results?.filter((result) => result?.category !== formCategories?.id);
        setRefillableTableData({
          forms: refillableForms,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          count: get(res, 'data.count'),
          isLoading: false,
        });
      })
      .catch(() => setRefillableTableData((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchPaginatedRefillableForms = async (url) => {
    setRefillableTableData((prevState) => ({ ...prevState, isLoading: true }));

    await getPaginatedSurveys(url)
      .then((res) => {
        const results = get(res, 'data.results', []);
        const refillableForms = results?.filter((result) => result?.category !== formCategories?.id);
        setRefillableTableData({
          forms: refillableForms,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          count: get(res, 'data.count'),
          isLoading: false,
        });
      })
      .catch(() => setRefillableTableData((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    if (formCategories) {
      fetchRefillableForms();
      fetchUserForms();
    }
  }, [selectedFilter, formCategories]);

  useEffect(() => {
    getFormCategories(companyId, `&code=${workProcessRatingCategoryCode}&limit=999`)
      .then((res) => {
        const result = get(res, 'data.results', null);
        setFormCategories(result && result[0]);
      });
  }, []);

  const handlePreviewForm = (id) => {
    const currentUrl = window.origin;
    const url = `${currentUrl}/surveys/worker-survey/${id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleOpenForm = async (e, row) => {
    e.stopPropagation();
    setActiveForm(row?.original);
    if (row.original?.is_finished) {
      handlePreviewForm(row.original.id);
      return;
    }
    if (!row?.original?.is_seen) {
      const data = {};
      data.is_seen = true;
      data.survey = row.original.survey.id;
      data.worker = row.original.worker.id;
      setIsRefill(false);
      await updateWorkerSurveys(row.original.id, data);
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setIsRefill(false);
    setActiveForm(null);
    fetchRefillableForms();
    fetchUserForms();
  };

  const handleRefillForm = async (row) => {
    const data = { ...row, workerId };
    setIsRefill(true);
    setActiveForm(data);
    setShowModal(true);
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_name')}</span>,
      accessor: 'survey.name',
      sortable: false,
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_open')}</span>,
      width: 80,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleOpenForm(e, row)}>
        <IconPopup
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_last_update')}</span>,
      accessor: 'updated_at',
      sortable: false,
      Cell: (row) => (row && row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_priority')}</span>,
      accessor: 'priority',
      sortable: false,
      Cell: (row) => (row && row.value ? t([`page_content.human_resources.forms.priority_${row.value}`]) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_status')}</span>,
      accessor: 'action_status',
      sortable: false,
      Cell: (row) => (row && row.value && row.original?.survey?.action_required ? t([`page_content.human_resources.forms.status_${row.value}`]) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_description')}</span>,
      accessor: 'survey.description',
      sortable: false,
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
  ];

  const refillTableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_name')}</span>,
      accessor: 'name',
      sortable: false,
      Cell: (row) => (row?.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_description')}</span>,
      accessor: 'description',
      sortable: false,
      Cell: (row) => (row?.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_fill_form_button')}</span>,
      width: 200,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button type="add" onClick={() => handleRefillForm(row?.original)}>
        {t('page_content.human_resources.forms.table_column_form_templates_fill_form_button')}
      </Button>
    </div>),
    },
  ];

  const filterOptions = [
    { label: t('page_content.human_resources.forms.filter_option_all'), value: '' },
    { label: t('page_content.human_resources.forms.filter_option_seen'), value: 'is_seen' },
    { label: t('page_content.human_resources.forms.filter_option_not_started'), value: 'not_started' },
    { label: t('page_content.human_resources.forms.filter_option_finished'), value: 'is_finished' },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '15px' }}>

      <div>
        <div className="forms_legend">
          <div className="form_legend_content">
            <div className="legend_shape_red"> </div> {t('page_content.human_resources.forms.legend_seen_label')}
          </div>
          <div className="form_legend_content">
            <div className="legend_shape_green"> </div> {t('page_content.human_resources.forms.legend_finished_label')}
          </div>
          <div className="forms_filters">
              <Select
                options={filterOptions}
                getOptionLabel={(type) => type.label}
                getOptionValue={(type) => type.value}
                placeholder={t('page_content.human_resources.forms.filter_status_placeholder')}
                menuPosition="fixed"
                onChange={(value) => setSelectedFilter(value)}
                value={selectedFilter || ''}
                styles={selectStyles}
              />
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumns}
          data={tableData?.forms || []}
          loading={tableData?.isLoading}
          noDataText=" "
          showPagination={false}
          minRows={0}
          getTrProps={(state, rowInfo) => {
            return {
              style: { backgroundColor: rowInfo.original.is_finished ? '#baf4b4' : rowInfo.original.is_seen ? '#ffd29e' : null } };
          }}
          defaultPageSize={30}
          selectedRow={null}
        />
      </div>

      <div>
        <Table
          style={{ userSelect: 'text' }}
          columns={refillTableColumns}
          data={refillableTableData?.forms || []}
          loading={refillableTableData?.isLoading}
          noDataText=" "
          showPagination={false}
          minRows={0}
          defaultPageSize={15}
        />
        <span style={{ float: 'right' }}>
          <TableButtons previous={refillableTableData?.previous} next={refillableTableData?.next} fetchFunction={fetchPaginatedRefillableForms} count={refillableTableData?.count} />
        </span>
      </div>

      <FormModal currentUser={currentUser} companyId={companyId} formData={activeForm} showModal={showModal} handleClose={handleClose} isRefill={isRefill} />

    </div>
  );
};

MyForms.propTypes = {
  t: PropTypes.func.isRequired,
  workerId: PropTypes.number,
  companyId: PropTypes.number,
  currentUser: PropTypes.object,
  workProcessRatingCategoryCode: PropTypes.string.isRequired,
//   userInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    workProcessRatingCategoryCode: get(state, 'app.location.config.work_process_rating_category_code', ''),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(MyForms));
