import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { get } from 'lodash';

import { IconEdit, IconPreviewDocument, IconSearch, IconUser } from 'shared/Icons';
import { Table, TableButtons, Button, DateFilters } from 'shared';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { selectStyles } from 'styles/modules/reactSelect';

import { getAllCategories, getForms, getPaginatedForms } from './actions';

import AddNewFormModal from './AddNewFormModal';
import AssignFormModal from './AssignFormModal';

const FormTemplates = ({ t, companyId, reFetchCategories, locationId }) => {
  const debounceTimeoutRef = useRef(null);
  const filterOptions = [
    { name: t('page_content.human_resources.forms.table_column_form_templates_created_at'), id: 'created_at' },
    { name: t('page_content.human_resources.forms.table_column_form_templates_last_update'), id: 'updated_at' }];
  const [query, setQuery] = useState(null);
  const [categories, setCategories] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({ date: filterOptions[0] });
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState({
    forms: [],
    count: null,
    previous: null,
    next: null,
    isLoading: true,
  });
  const [isAddFormModal, setIsAddFormModal] = useState(false);
  const [isAssignFormModal, setIsAssignFormModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [sortData, setSortData] = useState({ desc: true, id: 'updated_at' });
  const [clearDateFilters, setClearDateFilters] = useState(false);

  const fetchAllCategories = async () => {
    const res = await getAllCategories(companyId);
    setCategories(get(res, 'data.results', []));
  };

  const fetchSortedData = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    let filters = '';

    if (sortData) {
      const asc = sortData.desc ? '-' : '';
      const column = sortData.id;
      filters += `&order_by=${asc}${column || 'updated_at'}`;
    }

    if (query) {
      filters += `&name=${query}`;
    }
    if (dateFilter?.start && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_after=${moment(dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (dateFilter?.start && dateFilter?.end && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_before=${moment(dateFilter.end).format('YYYY-MM-DD')}`;
    }
    if (selectedFilter?.category) {
      filters += `&category=${selectedFilter.category.id}`;
    }
    if (selectedFilter?.user) {
      filters += `&user=${selectedFilter.user.id}`;
    }

    const res = await getForms(companyId, filters);
    setTableData({
      forms: get(res, 'data.results', []),
      count: get(res, 'data.count'),
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      isLoading: false,
    });
  };

  useEffect(() => {
    if (!users.length) {
      const data = tableData.forms;
      const usersData = data.map((val) => val.user);
      const newData = [...new Map((usersData).map((val) => [val.id, val])).values()];
      setUsers(newData);
    }
  }, [tableData.forms]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleFormAdded = () => {
    fetchSortedData();
  };

  const handleAddFormButtonModal = () => {
    setIsAddFormModal(true);
    setFormData(null);
  };

  const clearAllFilters = () => {
    setSelectedFilter({ date: filterOptions[0] });
    setDateFilter(null);
    setQuery('');
    setClearDateFilters((prevState) => !prevState);
  };

  const handleFilterChange = (value, key) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleEditButton = (e, row) => {
    e.stopPropagation();
    setFormData(row?.original);
    setIsAddFormModal(true);
  };

  const handleAssignButton = (row) => {
    setFormData(row?.original);
    setIsAssignFormModal(true);
  };

  const handlePreviewForm = (e, row) => {
    e.stopPropagation();
    const currentUrl = window.origin;
    const url = `${currentUrl}/surveys/empty-survey/${row?.original?.id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchSortedData();
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    fetchSortedData();
  }, [selectedFilter, dateFilter, sortData]);

  const fetchPaginatedForms = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getPaginatedForms(url);
    setTableData({
      forms: get(res, 'data.results', []),
      count: get(res, 'data.count'),
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      isLoading: false,
    });
  };

  const handleSorting = (sortingData) => {
    setSortData({ desc: sortingData.desc, id: sortingData.id });
  };

  const handleSelectedDateFilter = (filter, start, end) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      date: filter,
    }));
    setDateFilter({
      start, end,
    });
  };

  const handleCloseModal = () => {
    setFormData(null);
    setIsAddFormModal(false);
    setIsAssignFormModal(false);
  };

  const tableColumnsTemplates = [
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_edit')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleEditButton(e, row)}>
        <IconEdit
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_assign')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '0px 8px 0px 8px' }} onClick={() => handleAssignButton(row)} disabled={!row?.original?.is_published}>
        <IconUser
          height="24px"
          width="24px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>PDF</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handlePreviewForm(e, row)}>
        <IconPreviewDocument
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>{t('Published')}</span>,
      accessor: 'is_published',
      width: 150,
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>{row.value ?
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <img src={checkMarkTrue} width="25px" height="20px" alt="" />
          <span>{moment(row?.original?.publish_date).format(ordersCreatedAndUpdated)}</span>
        </div>
        :
        <img src={checkMarkFalse} width="25px" height="20px" alt="" />}
      </div>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_created_by')}</span>,
      accessor: 'user',
      width: 150,
      Cell: (row) => (row && row.value ? `${row.value.first_name} ${row.value.last_name}` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_created_at')}</span>,
      accessor: 'created_at',
      width: 150,
      Cell: (row) => (row && row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_last_update')}</span>,
      accessor: 'updated_at',
      width: 150,
      Cell: (row) => (row && row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_category')}</span>,
      accessor: 'category',
      width: 200,
      Cell: (row) => (row && row.value ? row.value.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_description')}</span>,
      accessor: 'description',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
  ];

  return (
    <div>
      <div className="filters_container">
        <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.human_resources.forms.search_by_name')} value={query || ''} />
              {query && <button
                onClick={() => setQuery('')}
              >&times;</button>}
            </div>
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
            </div>
          </div>
          <div>
            <Select
              className="select-style"
              options={categories}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.forms.filter_by_category')}
              onChange={(e) => handleFilterChange(e, 'category')}
              value={selectedFilter?.category || ''}
              styles={selectStyles}
            />
          </div>
          <div>
            <Select
              className="select-style"
              options={users}
              getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.forms.filter_by_user')}
              onChange={(e) => handleFilterChange(e, 'user')}
              value={selectedFilter?.user || ''}
              styles={selectStyles}
            />
          </div>
          </div>
          <div className="filters">
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={[
              { label: t('page_content.human_resources.forms.table_column_form_templates_created_at'), id: 'created_at' },
              { label: t('page_content.human_resources.forms.table_column_form_templates_last_update'), id: 'updated_at' }]}
            clearFilters={clearDateFilters}
          />
          <div className="clear_button">
              <Button
                type="plain"
                onClick={clearAllFilters}
              >
                {t('page_content.orders.clear_all_button')}
              </Button>
            </div>
          <div style={{ marginLeft: 'auto' }}>
          <Button type="add" onClick={handleAddFormButtonModal}>{t('page_content.human_resources.forms.button_add_new_form')}</Button>
            </div>
          </div>
          </div>
            <Table
              style={{ userSelect: 'text' }}
              columns={tableColumnsTemplates}
              data={tableData?.forms || []}
              loading={tableData.isLoading}
              noDataText=" "
              showPagination={false}
              minRows={0}
              defaultPageSize={15}
              selectedRow={null}
              defaultSorted={[{ id: 'updated_at', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
            />
            <TableButtons fetchFunction={fetchPaginatedForms} next={tableData.next} previous={tableData.previous} count={tableData.count} />
            {isAddFormModal &&
            <AddNewFormModal
              reFetchCategories={reFetchCategories}
              companyId={companyId}
              locationId={locationId}
              editFormData={formData}
              onBackClick={handleCloseModal}
              handleFormAdded={() => handleFormAdded()}
              showModal={isAddFormModal}
            />}
            {isAssignFormModal &&
            <AssignFormModal
              companyId={companyId}
              locationId={locationId}
              editFormData={formData}
              showModal={isAssignFormModal}
              handleFormAdded={() => handleFormAdded()}
              onBackClick={handleCloseModal}
            />}
    </div>
  );
};

FormTemplates.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  locationId: PropTypes.number,
  reFetchCategories: PropTypes.bool,
};

export default (withRouter(withTranslation()(FormTemplates)));
