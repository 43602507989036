import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';

import { multiSelectFixedOptionsAutoHeight, selectModalStyles } from 'styles/modules/reactSelect';
import { Notification, ContentLoader, Modal } from 'shared';

import { getAllWorkers, getDepartments, saveWorkerSurveys } from './actions';

import SelectedWorkersBox from './SelectedWorkersBox';

const AssignFormModal = ({
  t,
  showModal,
  companyId,
  locationId,
  onBackClick,
  editFormData,
  handleFormAdded,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState('');

  const priorityOptions = [
    { id: 'optional', label: t('page_content.human_resources.forms.priority_optional') },
    { id: 'required', label: t('page_content.human_resources.forms.priority_required') },
    { id: 'high', label: t('page_content.human_resources.forms.priority_high') },
  ];

  const fetchUsers = async (filter = '') => {
    setIsLoading(true);
    getAllWorkers(locationId, companyId, filter)
      .then((re) => {
        setUsers(get(re, 'data.results', []) || []);
        setIsLoading(false);
      })
      .catch(() => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const fetchDepartments = async () => {
    setIsLoading(true);
    const res = await getDepartments(locationId);
    setDepartments(get(res, 'data.results', []));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
  }, [editFormData]);

  const clearValues = () => {
    setSelectedUsers([]);
  };
  const handleAssignForm = async () => {
    await Promise.all(
      selectedUsers.map(async (u) => {
        const workerSurveyData = {
          worker: u?.id,
          priority: selectedPriority?.id || 'optional',
          survey: editFormData?.id,
          is_action_required: editFormData?.action_required || false,
        };
        await saveWorkerSurveys(workerSurveyData);
      }),
    ).then(() => {
      Notification('success', t('page_content.human_resources.forms.successfully_saved_notification'));
      clearValues();
      handleFormAdded();
      onBackClick();
    }).catch(() => {
      clearValues();
      handleFormAdded();
      onBackClick();
    });
  };

  const handleSelectedUser = (userValues) => {
    let data;
    const userAlreadySelected = selectedUsers.find(
      (u) => u.id === userValues.id,
    );
    if (userAlreadySelected?.isFixed) {
      return;
    }
    if (userAlreadySelected) {
      data = selectedUsers.filter((u) => u.id !== userAlreadySelected.id);
    } else {
      data = [...selectedUsers, userValues];
    }
    setSelectedUsers(data);
  };

  const handleSelectedDepartment = (departmentValues) => {
    const filterUsers = users.filter(
      (u) => u?.location_department?.id === departmentValues?.id,
    );
    const filterSelectedUsers = filterUsers.filter(
      (u) => !selectedUsers.some((user) => user.id === u.id),
    );
    const newSelectedUsers = [...selectedUsers, ...filterSelectedUsers];
    setSelectedUsers(newSelectedUsers);
  };

  const handleRemoveWorker = (value) => {
    const data = selectedUsers.filter((w) => w.id !== value.id);
    setSelectedUsers(data);
  };

  const handleRemoveDepartmentWorkers = (department) => {
    const data = selectedUsers.filter(
      (w) => w.isFixed || w.location_department?.name !== department,
    );

    setSelectedUsers(data);
  };

  const customFormatOptionLabel = (user) => {
    const style = {};
    let label = `${user.name} ${user.last_name}`;
    const workerFound = selectedUsers.find((u) => u.id === user.id);
    if (workerFound?.isFixed) {
      style.color = 'gray';
      style.cursor = 'not-allowed';
      label += ` (${t('page_content.human_resources.forms.sent_label')})`;
    } else if (workerFound && !workerFound?.isFixed) {
      style.color = 'green';
      label += ` (${t('page_content.human_resources.forms.selected_label')})`;
    }

    return <div style={style}>{label}</div>;
  };

  const customFormatOptionLabelDepartments = (dep) => {
    const style = {};
    const statusStyle = { color: 'gray' };
    const label = `${dep.name}`;
    let status = '';

    const usersFromDepartment = users.filter(
      (u) => u?.location_department?.id === dep?.id,
    );
    const selectedUsersFromDepartment = usersFromDepartment.filter((u) => selectedUsers.some((user) => user.id === u.id));
    const allSelected =
      selectedUsersFromDepartment.length === usersFromDepartment.length;

    if (allSelected && selectedUsersFromDepartment.length > 0) {
      status = ` (${t(
        'page_content.human_resources.forms.all_selected_label',
      )})`;
    } else if (selectedUsersFromDepartment.length > 0) {
      status = ` (${selectedUsersFromDepartment.length} ${t(
        'page_content.human_resources.forms.selected_label',
      )})`;
    }

    return (
      <div style={style}>
        {label}
        <span style={statusStyle}>{status}</span>
      </div>
    );
  };

  const UsersPlaceholder = () => {
    if (!selectedUsers.length) return (<div>{t('page_content.human_resources.forms.modal_placeholder_select_workers')}</div>);

    return (
      <div className="placeholder_selected_users">
        {selectedUsers.slice(0, 2).map((user, index) => (
          <div key={index} className="placeholder_selected_users-name">
            {`${user.name} ${user.last_name}`}
          </div>
        ))}
        {selectedUsers.length > 2 && (
          <div style={{ fontSize: 20, fontWeight: 700 }}>...</div>
        )}
      </div>
    );
  };

  const handleSelectPriority = (value) => {
    setSelectedPriority(value);
  };

  return (
    <Modal
      size="large"
      isOpen={showModal}
      handleSave={handleAssignForm}
      handleClose={() => { clearValues(); onBackClick(); }}
      disableSave={!selectedUsers.length || !selectedPriority}
      title={t('page_content.human_resources.forms.assign_form_title')}
    >
      <div style={{ padding: '12px 24px' }}>
        {isLoading ? (
          <ContentLoader />
        ) : (
          <div>
            <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_priority_label')}</div>
                <div className="right_select">
                  <Select
                    options={priorityOptions}
                    getOptionLabel={(p) => p.label}
                    getOptionValue={(p) => p.id}
                    placeholder={t('page_content.human_resources.forms.modal_placeholder_select_priority')}
                    menuPosition="fixed"
                    onChange={handleSelectPriority}
                    value={priorityOptions.find((p) => p.id === selectedPriority?.id) || ''}
                    styles={multiSelectFixedOptionsAutoHeight}
                  />
                </div>
            </div>
            <div className="send_to_container">
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_send_to_department_label')}</div>
                <div className="right_select">
                  <Select
                    options={departments}
                    getOptionLabel={(type) => type.name}
                    getOptionValue={(type) => type.id}
                    placeholder={t('page_content.human_resources.forms.modal_form_department_placeholder')}
                    isSearchable
                    menuPosition="fixed"
                    onChange={handleSelectedDepartment}
                    value={null}
                    formatOptionLabel={customFormatOptionLabelDepartments}
                    styles={selectModalStyles}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_send_to_label')}</div>
                <div className="right_select">
                  <Select
                    options={users}
                    getOptionLabel={(user) => `${user.name} ${user.last_name}`}
                    getOptionValue={(user) => user.id}
                    placeholder={<UsersPlaceholder />}
                    isSearchable
                    menuPosition="fixed"
                    onChange={handleSelectedUser}
                    value={null}
                    formatOptionLabel={customFormatOptionLabel}
                    styles={multiSelectFixedOptionsAutoHeight}
                  />
                </div>
              </div>
              <div className="selected-workers">
                <SelectedWorkersBox
                  workers={selectedUsers}
                  handleRemoveDepartmentWorkers={handleRemoveDepartmentWorkers}
                  handleRemoveWorker={handleRemoveWorker}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

AssignFormModal.propTypes = {
  showModal: PropTypes.bool,
  companyId: PropTypes.number,
  onBackClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  editFormData: PropTypes.object,
  handleFormAdded: PropTypes.func,
  locationId: PropTypes.number.isRequired,
};

export default withRouter(withTranslation()(AssignFormModal));
