import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ScoringCategories from './components/ScoringCategories';
import IndividualGoals from './components/IndividualGoals';
import CompanyValues from './components/CompanyValues';

const ControlPanel = ({ t, companyId, locationId, userHaveFullAccess, currentUser }) => {
  return (
    <Tabs>
    <TabList>
      <Tab>{t('page_content.human_resources.control_panel.tab_category_scoring')}</Tab>
      <Tab>{t('page_content.human_resources.control_panel.tab_individual_goals')}</Tab>
      {currentUser?.is_admin && <Tab>{t('page_content.human_resources.control_panel.tab_kfk_values')}</Tab>}
    </TabList>

    {currentUser?.is_admin && <TabPanel>
        <ScoringCategories />
    </TabPanel>}

    <TabPanel>
        <IndividualGoals
          companyId={companyId}
          locationId={locationId}
          userHaveFullAccess={userHaveFullAccess}
        />
    </TabPanel>

    <TabPanel>
        <CompanyValues
          companyId={companyId}
          userHaveFullAccess={userHaveFullAccess}
        />
    </TabPanel>
  </Tabs>
  );
};

ControlPanel.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(ControlPanel));
